import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuDrawer from '../sidebar/menu-drawer.jsx'
import { getSettings } from '../../actions/settingsActions'
import { withRouter } from 'react-router-dom'
import {config} from '../../config';

class TemplateLayout extends Component {
    constructor() {
        super();
    }

    componentDidMount() {        
        let sidebar = document.getElementById('sidebar').style.display = "none";
        let header = document.getElementById('header').style.display = "none";
    }

    render() {
        return (
            <div>
                <embed style={{height: '100vh'}} 
                src={process.env.REACT_APP_API_URL +  this.props.match.url + '?token='+localStorage.jwtToken} 
                type="application/pdf" width="100%" />
            </div>
        );
    }
}

TemplateLayout.propTypes = {
    auth: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    children: PropTypes.node,
};

const mapStateToProps = state => ({
        auth: state.auth,
        settings: state.settings
    });

export default connect(mapStateToProps, {getSettings})(withRouter(TemplateLayout));
