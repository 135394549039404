import React, { Component } from 'react';
import { render } from "react-dom";
import Translate, { plainTranslate } from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuContent from './menuContent';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Button, Input } from 'antd';
import 'antd/lib/icon/style/index.css';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/button/style/index.css';
import AsyncSelect from 'react-select/lib/Async';
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'
import { RightOutlined, FormOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'

class MenuContents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showContent: false,
            visible: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.addNewSubItem = this.addNewSubItem.bind(this);
        this.showContent = this.showContent.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.remove = this.remove.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    update() {
        this.setState({
            value: this.props.item ? this.props.item : []
        });
    }

    addNewSubItem() {
        let menu = this.state.value ? this.state.value[this.props.fieldSettings.subLevel] : [];
        if (menu) {
            let newItem = { 'id': (menu.length + 1), 'name': 'New subelement to ' + this.props.item.name, 'level': (this.props.level * 1) + 1 };
            menu.push(newItem);
        } else {
            menu = this.state.value;
            let newItem = { 'id': 1, 'name': 'New subelement to ' + this.props.item.name, 'level': (this.props.level * 1) + 1 };
            menu[this.props.fieldSettings.subLevel] = [];
            menu[this.props.fieldSettings.subLevel].push(newItem);
        }
        this.props.change();
        this.setState({
            menu
        });
    }

    handleChange(e, type) {
        let value = this.state.value;   
        value[type] = e.target.value;
        this.setState({ value: value });
        
    }

    handleCategoryChange(val) {
        let value = this.state.value;
        value['category'] = val;
        value['bannerLink'] = val.image;
        value['image'] = val.imageTitle;
        value['url'] = val.url;
        if (value['name'].includes('element')) {
            value['name'] = val.name;
        }
        this.setState({ value: value });
        this.props.change();
    }

    showContent() {
        this.setState({ showContent: !this.state.showContent });
    }

    showModal() {
        this.setState({
            visible: true,
        });
    }

    handleOk() {
        this.props.change();
        this.setState({
            visible: false,
        });
    }

    handleCancel() {
        this.setState({
            visible: false,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.item != this.props.item) {
            this.setState({
                value: this.props.item
            })
        }
    }
    

    removeFromTree(tree, itemToDelete) {
        let index;
        if (tree.hasOwnProperty(this.props.fieldSettings.subLevel)) {
            tree[this.props.fieldSettings.subLevel].find((val, key) => {
                if (val === itemToDelete) {
                    index = key;
                    if (index > -1) {
                        tree[this.props.fieldSettings.subLevel].splice(index, 1);
                    }
                    return true;
                } else {
                    this.removeFromTree(val, itemToDelete);
                }
            });
        }
    }

    remove(itemToDelete) {
        let tree = this.state.value;
        let index;
        if (tree.hasOwnProperty(this.props.fieldSettings.subLevel)) {
            tree[this.props.fieldSettings.subLevel].find((val, key) => {
                if (val === itemToDelete) {
                    index = key;
                    if (index > -1) {
                        tree[this.props.fieldSettings.subLevel].splice(index, 1);
                    }
                    return true;
                } else {
                    this.removeFromTree(val, itemToDelete);
                }
            });
        }

        this.setState({ value: tree });
        this.props.change();
    }

    loadOptions(inputValue, callback) {
        client.get('/' + this.props.settings.db + '/api/search-categories?q=' + inputValue)
            .then(response => response.data)
            .then(response => {
                let options = [];
                if (response) {
                    options = response.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
                this.setState({ options: options });
                callback(options ? options : this.state.options);
            });

    }

    render() {
        return (
            <div>
                {this.state.value &&
                    <div className="row">
                        <div className="accordion col-md-12 m-bot15">
                            <div className="accordion-group">
                                <div className="accordion-heading area collapsible col-md-12 no-gutter">
                                    <div className="col-md-9 col-xs-6" onClick={this.showContent}>
                                        {this.state.value && this.state.value[this.props.fieldSettings.subLevel] && this.state.value[this.props.fieldSettings.subLevel].length > 0 &&
                                            <span style={{ marginRight: 10 }}>
                                                <RightOutlined />
                                            </span>
                                        }
                                        <strong>{this.state.value.name}</strong>
                                    </div>
                                    <div className="col-md-3 col-xs-6">
                                        <span style={{ marginRight: 10 }} onClick={this.showModal}><FormOutlined title={plainTranslate(this.props.locale, 'Edit menu item')} /> {plainTranslate(this.props.locale, 'Edit')}</span>
                                        {this.props.fieldSettings && this.props.fieldSettings.totalLevels > 1 &&
                                            <span style={{ marginRight: 10 }} onClick={this.addNewSubItem}><PlusCircleOutlined title={plainTranslate(this.props.locale, 'Add new subelement')} /> {plainTranslate(this.props.locale, 'Add new')}</span>
                                        }
                                        <span onClick={() => this.props.remove(this.state.value)}><DeleteOutlined title={plainTranslate(this.props.locale, 'Remove menu item')} /> {plainTranslate(this.props.locale, 'Delete')}</span>
                                    </div>
                                </div>
                                {this.state.showContent &&
                                    <div className="content">
                                        <Droppable droppableId={this.props.type} type={this.props.type}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                              >
                                                {this.state.value && this.state.value[this.props.fieldSettings.subLevel] && this.state.value[this.props.fieldSettings.subLevel].length > 0 && this.state.value[this.props.fieldSettings.subLevel].map((child, key) =>
                                                  <Draggable key={child.id} draggableId={child.name} index={key}>
                                                    {(provided, snapshot) => (
                                                      <div style={{ display: "table", width: '100%' }}>
                                                        <div
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <MenuContent type={child.name} item={child} key={key} change={this.props.change} remove={this.remove} level={(this.props.level * 1) + 1} options={this.props.options} db={this.props.settings.db} fieldSettings={this.props.fieldSettings}/>
                                                        </div>
                                                        {provided.placeholder}
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                    </div>
                                }
                            </div>
                        </div>
                        <Modal
                            title={plainTranslate(this.props.locale, 'Edit item')}
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                        >
                            <div>
                                {this.props.fieldSettings.fields.map((item, key) => {
                                    return <div className="form-group" key={key}>
                                        <label className="text-left">{plainTranslate(this.props.locale, item)}</label>
                                        <input type="text" className="form-control" onChange={(e) => this.handleChange(e, item)}
                                            value={this.state.value[item]}
                                        />
                                    </div>
                                    })
                                }
                                {this.props.fieldSettings.contentCategory &&
                                    <div className="form-group">
                                        <label className="text-left">{plainTranslate(this.props.locale, 'Category')}</label>
                                        <AsyncSelect
                                            isClearable
                                            cacheOptions
                                            isSearchable
                                            onChange={this.handleCategoryChange}
                                            onBlurResetsInput={false}
                                            onBlur={event => event.preventDefault()}
                                            onCloseResetsInput={false}
                                            menuPlacement={"auto"}
                                            loadOptions={this.loadOptions}
                                            defaultOptions={this.props.options}
                                            value={this.state.value.category}
                                        />
                                    </div>
                                } 
                            </div>                          
                        </Modal>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MenuContents);
