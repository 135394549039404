import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import { Menu, Dropdown, Button, Input, Drawer,Tooltip, Switch } from 'antd';
import { PlusOutlined, FilterOutlined, SearchOutlined, CloseCircleOutlined, DownOutlined, CloseSquareOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import { plainTranslate } from '../common/translate';
import CustomFilters from './customFilters';

const GridHeader = (props) => {
  const [showSearchField, setShowSearchField] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState();
  const [clearSelectedFilters, setClearSelectedFilters] = React.useState(false);
  const [gridChanged, setGridChanged] = useState(false);

  const ref = useRef();
  const isInitialMount = useRef(true);

  const menu = (
    <Menu>
    {props.gridHeader && props.gridHeader.newDropdownButton && props.gridHeader.newDropdownButton.map((option,key) =>{
      return <Menu.Item key={key}><Link to={option.link}>{option.label}</Link></Menu.Item>
    })}
    </Menu>
  );

  let dropdownOptions = [];
  if(props.gridHeader){
      dropdownOptions = (
        <Menu>
          {props.gridHeader && props.gridHeader.dropdown && props.gridHeader.dropdown.map((option, i) => (
            <Menu.Item key={i}>
                <Link to={props.location.pathname+(option.id ? '?grid='+option.id : '')}>
                   {option.label}
                </Link>
            </Menu.Item>
          ))}
        </Menu>
      );
  }

  function onSearchClick(){
    setShowSearchField(!showSearchField);
  }

  function showFiltersPopup(){
    setShowFilters(!showFilters);
  }

  function clearFilters(){
    props.onCustomFilterChange([]);
    setSelectedFilters([]);
    setClearSelectedFilters(true);
  }

  function filtersChange(type,keys,selectAll,search){
    if(clearSelectedFilters){
      setClearSelectedFilters(false);
    }else{
      let filters = Object.assign({}, selectedFilters);
      filters[type] = keys;
      setSelectedFilters(filters);
      props.onCustomFilterChange(filters);
    }
  }

  function onSwitchChange(value){
   filtersChange('deleted', value);
  }

  useEffect(() => {
    setSelectedFilters(props.gridProperties.header.customFilters);
    setGridChanged(props.gridId ? props.gridId : props.refsId);
  }, [props.gridProperties.header.customFilters]);

  return (
    <div className="moduleSubMenu" style={{paddingLeft: 0, paddingRight: 0,backgroundColor: '#fff', marginBottom: 4}}>
      <div className="col-sm-12 col-xs-12">
        <div className="col-md-4 col-xs-8" style={{display: "flex",alignItems: "center"}}>
          {
            props.gridHeader && props.gridHeader.dropdown &&
              <Dropdown overlay={dropdownOptions}>
                <Button size="large" style={{padding: 0,border: "none", fontSize: 24, boxShadow: "none",marginRight: 10, display: "flex",alignItems: "baseline"}}>
                 <Tooltip placement="right" title={props.gridHeader.title}><span className="gridHeader-tooltip" style={{overflow: "hidden",textOverflow: "ellipsis"}}>{props.gridHeader.title}</span></Tooltip><DownOutlined />
                </Button>
              </Dropdown>
          }
          {
            props.gridHeader && !props.gridHeader.dropdown &&
            <Tooltip placement="right" title={props.gridHeader.title}><h3 className="module-title pull-left" style={{marginRight: 10, overflow: "hidden",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>
              {props.gridHeader.title}
            </h3></Tooltip>
          }
          {props.gridHeader && props.gridHeader.newDropdownButton && 
            window.innerWidth >= 767 &&<Dropdown overlay={menu} className="grid-button-new">
                <Button type="text">
                  <span className="button-title">{plainTranslate(props.settings.locale, "New")}</span><span className="caret"></span>
                </Button>
            </Dropdown>
          }
          {props.gridHeader && props.gridHeader.newDropdownButton && 
            window.innerWidth < 767 &&<Dropdown overlay={menu} className="grid-button-new">
                <Button type="text">
                  <PlusOutlined />
                </Button>
            </Dropdown>
          }
          {props.gridHeader && props.gridHeader.newButton && 
            <Link to={props.gridHeader.newButton}>
              <Button type="text" className="grid-button-new">
                {window.innerWidth >= 767 && plainTranslate(props.settings.locale, "New")}
                {window.innerWidth < 767 && <PlusOutlined />}
              </Button>
            </Link>
          }
          {props.gridHeader && props.gridHeader.additionalButton && 
            <Link to={props.gridHeader.additionalButton.link}>
              <Button type="text" className="grid-button-new">
                {window.innerWidth >= 767 && plainTranslate(props.settings.locale, props.gridHeader.additionalButton.label)}
                {window.innerWidth < 767 && <PlusOutlined />}
              </Button>
            </Link>
          }
        </div>
        <div className="col-md-8 col-xs-4" style={{display: "flex", paddingTop: 5, overflowX: "auto", height: 65}}>
          {props.gridProperties.options && props.gridProperties.options && props.gridProperties.options.filters && window.innerWidth >= 768 && 
            props.gridProperties.options.filters.map((filter,index) => {
              if(filter.type === 'deleted'){
                return <div style={{width: 120}}><Switch checked={selectedFilters ? selectedFilters.deleted : false} onChange={onSwitchChange} style={{verticalAlign: "sub", marginRight: 10, whiteSpace:"nowrap"}} checkedChildren={plainTranslate(props.settings.locale, 'Deleted')} unCheckedChildren={plainTranslate(props.settings.locale, 'Not deleted')} /></div>
              }else{
                return <div style={{marginLeft: index === 0 ? 'auto' : ''}}><CustomFilters gridChanged={gridChanged} clear={clearSelectedFilters} selectedFilters={selectedFilters ? selectedFilters[filter.type] ? selectedFilters[filter.type] : [] : []} filter={filter} onChange={filtersChange} startDate={props.params.startDate} endDate={props.params.endDate}/></div>
              }
            })}
          {props.gridProperties.options && props.gridProperties.options.filters && props.gridProperties.options.filters.length > 0 && window.innerWidth >= 767 && <div style={{display: "inline-block", fontSize: 20, cursor: 'pointer'}} onClick={clearFilters}><CloseSquareOutlined /></div>}
          {props.gridProperties.options && props.gridProperties.options.filters && window.innerWidth < 768 && <FilterOutlined style={{fontSize: 22, paddingTop: 5, color:"#454545"}} onClick={showFiltersPopup}/>}
          {props.gridProperties.options.enableQuickFilter && window.innerWidth < 768 && (showSearchField ? <CloseCircleOutlined onClick={onSearchClick} style={{fontSize: 22, paddingTop: 5, marginLeft: 20, color:"#454545"}}/> : <SearchOutlined onClick={onSearchClick} style={{fontSize: 22, paddingTop: 5, marginLeft: 20, color:"#454545"}}/>)}
          {showSearchField && <Input size="small" allowClear className="mobile-search" onChange={props.onGridSearchChange} prefix={<SearchOutlined />}/>}
          <Drawer className="grid-popup-form" placement="right" visible={showFilters} width={'90%'} onClose={showFiltersPopup} title={plainTranslate(props.settings.locale, 'Custom Filters')}>
          {props.gridProperties.options && props.gridProperties.options && props.gridProperties.options.filters && props.gridProperties.options.filters.map(filter => {
                return <CustomFilters gridChanged={gridChanged} clear={clearSelectedFilters} selectedFilters={selectedFilters ? selectedFilters[filter.type] ? selectedFilters[filter.type] : [] : []} filter={filter} onChange={filtersChange} startDate={props.params.startDate} endDate={props.params.endDate}/>
          })}
          <Button onClick={clearFilters} danger style={{marginTop: 50}} className="mobileFiltersGridBtn">{plainTranslate(props.settings.locale,'Clear All Filters')}</Button>
          <Button onClick={showFiltersPopup} className="grid-button-new mobileFiltersGridBtn" style={{marginTop: 15}}>{plainTranslate(props.settings.locale,'Apply')}</Button>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GridHeader));
