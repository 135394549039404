import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { plainTranslate } from '../common/translate';
import SaveCancel from '../common/saveCancelButtons';
import { Field as FormField, reduxForm } from 'redux-form';
import Field from './field';
import validate from './validator';
import "./redux-forms.css";
import PosPage from "../custom-forms/posPage";
import MakePaymentPage from "../custom-forms/makePaymentPage";
import {
    onLoadForm as onLoadForm,
    onImportLoadForm as onImportLoadForm
} from "../../actions/formActions";
import FormLoader from "../forms/form-loader";
import { onSave, onSidebarMount } from "../../actions/actions";
import { FORM_SHOW_LOADER } from "../../constants/actionTypes";
import { Modal } from 'antd';
import 'antd/lib/modal/style/index.css';
import { Switch } from 'antd';
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import {config} from '../../config';

const components = {
    pos: PosPage,
    payment: MakePaymentPage
};

class InitializeFromStateForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false
        };

        this.formRoute = '';
        this.saveRoute = '';
        this.download = '';
        this.updateForm = this.updateForm.bind(this);
        this.save = this.save.bind(this);
        this.apply = this.apply.bind(this);
        this.changeFieldValue = this.changeFieldValue.bind(this);
        this.onBreadcrumbClick = this.onBreadcrumbClick.bind(this);
        this.checkDeviceAndPrint = this.checkDeviceAndPrint.bind(this);
        this.redirectOnClick = this.redirectOnClick.bind(this);
        this.reportSaveAs = this.reportSaveAs.bind(this);
        this.disableSave = this.disableSave.bind(this);
    }

    changeFieldValue(id, value) {
        this.props.change(id, value);
    }

    componentDidMount() { 
        window.addEventListener('beforeunload', this.handleUnload); 
        this.updateForm();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
    }

    handleUnload(e) {
            var message = "\o/";

            (e || window.event).returnValue = message;
            
            return message;
    
    }

    onBreadcrumbClick(element, target) {
        if (element.status == 8) {
            showAToast(plainTranslate(this.props.settings.locale, 'Document cancelled. Review all attached documents!'), 'errSettings', 'error');
        }

        if (this.props.formProperties.isEdit) {
            let pos = this.props.formData['stagePosition'];
            if (pos) {
                if (element.position >= pos) {
                    this.changeFieldValue(target, element.value);

                    if (this.props.formProperties.form.breadcrumb.changeLines) {
                        if (this.props.formData && this.props.formData.lines) {
                            this.props.formData.lines.map((line, key) => {
                                this.props.change('lines[' + key + '][stage]', element.value);
                                this.props.change('lines[' + key + '][stageStatus]', element.status);
                            })
                        }
                    }
                } else {
                    showAToast(plainTranslate(this.props.settings.locale, 'Cannot change document Status backward.'), 'errSettings', 'error');
                }
            } else {
                this.changeFieldValue(target, element.value);
            }
            if (element.status == 8 && element.manySalesInvoice) {
                showAToast(plainTranslate(this.props.settings.locale, 'Cancellation forbidden. The document is part of a group.'), 'errSettings', 'error');
            }
        } else {
            this.changeFieldValue(target, element.value);

            if (this.props.formProperties.form.breadcrumb.changeLines) {
                if (this.props.formData && this.props.formData.lines) {
                    this.props.formData.lines.map((line, key) => {
                        this.props.change('lines[' + key + '][stage]', element.value);
                        this.props.change('lines[' + key + '][stageStatus]', element.status);
                    })
                }
            }
        }

    }

    componentDidUpdate(prevProps) {
        if ((this.props.match && prevProps.match.params.id !== this.props.match.params.id) || (prevProps.location && this.props.location && prevProps.location.search !== this.props.location.search)) {
            this.updateForm();
        }
    }

    updateForm() {
        this.props.onLoader(this.props.form, true);
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }

        if(this.props.match.params.db){
            this.saveRoute = '/' + this.props.match.params.db + this.props.saveRoute;
            this.formRoute = '/' + this.props.match.params.db + this.props.formRoute;
        }else{
            this.saveRoute = this.props.saveRoute;
            this.formRoute = this.props.formRoute;
        }

        if (this.props.match) {
            this.formRoute = this.formRoute + this.props.match.params.id;
        }

        if (this.props.isNew) {
            this.formRoute = this.formRoute + '/form';
            if (this.saveRoute[this.saveRoute.length - 1] === '/') {
                this.saveRoute = this.saveRoute + this.props.match.params.id;
            }
        }

        if (this.props.match && !this.props.isNew) {
            let params = [];
            for (var prop in this.props.match.params) {
                params.push(prop + '=' + this.props.match.params[prop]);
            }
            if (params.length > 0) {
                this.saveRoute += '?' + params.join('&');
            }
        }

        if (this.props.matchAdditionalId) {
            this.formRoute = this.formRoute + '/' + this.props.matchAdditionalId.params.id;
        }

        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search);
            const key = urlParams.get('refs');
            if (key !== null) {
                this.formRoute = this.formRoute + '/' + key;
                this.saveRoute = this.saveRoute + '?refs=' + key;
            }
        }
        if (this.props.account) {
            this.formRoute = this.formRoute + '/' + this.props.account;
        }
        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search);
            const key = urlParams.get('ids');
            const lineIds = urlParams.get('lineIds');
            const page = urlParams.get('page');
            const itemIds = urlParams.get('itemIds');
            const item = urlParams.get('item');
            const saleLine = urlParams.get('saleLine');
            const saleLineIds = urlParams.get('saleLineIds');
            const qty = urlParams.get('qty');
            const location = urlParams.get('location');
            const module = urlParams.get('module');
            const type = urlParams.get('type');

            if(lineIds !== null){
                this.formRoute = this.formRoute + '?lineIds=' + lineIds;
            }
            if (key !== null && module !== null && type !== null) {
                this.formRoute = this.formRoute + '?ids=' + key + '&module=' + module + '&type=' + type;
                this.saveRoute = this.saveRoute;
            } else if (key !== null && module !== null) {
                this.formRoute = this.formRoute + '?ids=' + key + '&module=' + module;
                this.saveRoute = this.saveRoute;
            } else if (key !== null) {
                this.formRoute = this.formRoute + '?ids=' + key;
                this.saveRoute = this.saveRoute;
            } else if (itemIds !== null) {
                this.formRoute = this.formRoute + '?itemIds=' + itemIds;
                if (location) {
                    this.formRoute = this.formRoute + '&location=' + location;
                }
                this.saveRoute = this.saveRoute + '?itemIds=' + itemIds;
                if (page) {
                    this.formRoute = this.formRoute + '&page=' + page;
                    this.saveRoute = this.saveRoute + '&page=' + page;
                }

            } else if (item !== null) {
                this.formRoute = this.formRoute + '?item=' + item;
                if (location) {
                    this.formRoute = this.formRoute + '&location=' + location;
                }
                if (qty) {
                    this.formRoute = this.formRoute + '&qty=' + qty;
                }
                this.saveRoute = this.saveRoute + '?item=' + item;
                if (saleLine) {
                    this.formRoute = this.formRoute + '&saleLine=' + saleLine;
                    this.saveRoute = this.saveRoute + '&saleLine=' + saleLine;
                }   
            } else if (saleLineIds) {
                this.formRoute = this.formRoute + '?saleLineIds=' + saleLineIds;
                this.saveRoute = this.saveRoute + '?saleLineIds=' + saleLineIds;
                if (location) {
                    this.formRoute = this.formRoute + '&location=' + location;
                }
            }
        }
        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search);
            const key = urlParams.get('type');
            if (key !== null && module == null) {
                this.formRoute = this.formRoute + '?type=' + key;
            }
        }
        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search);
            const key = urlParams.get('locId');
            const type = urlParams.get('type');

            if (key !== null && type !== null) {
                this.formRoute = this.formRoute + '&locId=' + key;
            }

            if (key !== null && type === null) {
                this.formRoute = this.formRoute + '?locId=' + key;
            }
        }

        if (this.props.refs && this.props.type && this.props.sale) {
            this.formRoute = this.formRoute + '?refs=' + this.props.refs + '&type=' + this.props.type + '&sale=' + this.props.sale;
        }
        else if ((this.props.refs || this.props.refs === null) && this.props.type) {
            this.formRoute = this.formRoute + '?refs=' + this.props.refs + '&type=' + this.props.type;
            if (this.props.parent) {
                this.formRoute = this.formRoute + '&parent=' + this.props.parent;
            }
        } else if (this.props.refs) {
            this.formRoute = this.formRoute + '?refs=' + this.props.refs;
        } else if (this.props.type) {
            this.formRoute = this.formRoute + '?type=' + this.props.type;
        } else if (this.props.copy) {
            this.formRoute = this.formRoute + '?copy=' + this.props.copy;
        } else if (this.props.accountId) {
            this.formRoute = this.formRoute + '?accountId=' + this.props.accountId;
            this.saveRoute = this.saveRoute + '&accountId=' + this.props.accountId;
        }

        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search);
            const key = urlParams.get('storno');
            const copyKey = urlParams.get('copy');
            const deal = urlParams.get('deal');

            if (key !== null) {
                this.formRoute = this.formRoute + '?storno=' + key;
            }
            if (copyKey !== null) {
                this.formRoute = this.formRoute + '?copy=' + copyKey;
            }
            if (deal !== null) {
                this.formRoute = this.formRoute + '&deal=' + deal;
            }

        }

        if(this.props.start){
            this.formRoute = this.formRoute + '?start=' + this.props.start;
        }
        if(this.props.end){
            this.formRoute = this.formRoute + '&end=' + this.props.end;
        }
        let importData = this.props.location ? this.props.location.state : null;
        if (importData) {
            this.props.onImportLoadForm(this.props.form, this.props.settings.db, importData);
        } else {
            if (this.props.applyFilters) {
                this.props.onImportLoadForm(this.props.form, this.props.settings.db, this.props.importData, this.formRoute);
            } else {
                this.props.onLoadForm(this.props.form, this.formRoute);
            }
        }
    }

    printStorno(url) {
        let items = [];

        this.props.formData.sale && this.props.formData.sale.lines.map(line => {
            let item = {};
            let price = (line.unitPrice * (line.taxRate / 100)) + line.unitPrice;

            item.text = line.itTitle ? line.itTitle : line.itemDescription;
            if (line.quantity < 0) {
                item.quantity = line.quantity * -1;
            } else {
                item.quantity = line.quantity;
            }
            item.unitPrice = price.toFixed(2);
            item.taxGroup = 2;

            if (line.discountRate) {
                item.priceModifierValue = line.discountRate;
                item.priceModifierType = "discount-percent";
            }
            items.push(item);
        })

        let paymentName = null;
        this.props.formProperties.form.fields.map(field => {
            if (field.id === 'payId') {
                let paymentMethod = field.options.find(x => x.value === this.props.formData.payId);
                paymentName = paymentMethod.label;
            }
        })
        let body = null;

        let amountOriginal = this.props.formData.amountOriginal;

        if (amountOriginal < 0) {
            amountOriginal = amountOriginal * -1;
        }

        if (amountOriginal < this.props.formData.documentAmount) {
            body = '{"uniqueSaleNumber": "' + this.props.formData.sale.documentExtNo + '","items": [{"text":"' + paymentName + '", "unitPrice":' + amountOriginal + ', "taxGroup": 2}],"payments": [{"amount": ' + amountOriginal + ',"paymentType": "' + this.props.formData.paymentMethodType + '"}], "receiptNumber":"' + this.props.formData.stornoReceiptData.documentNo + '" , receiptDateTime: "' + this.props.formData.stornoReceiptData.documentDate + '" , fiscalMemorySerialNumber: "' + this.props.formData.stornoReceiptData.fiscalMemorySerialNumber + '" , reason: "' + this.props.formData.stornoReason + '"}}';
        } else {
            body = '{"uniqueSaleNumber": "' + this.props.formData.sale.documentExtNo + '","items": ' + JSON.stringify(items) + ',"payments": [{"amount": ' + amountOriginal + ',"paymentType": "' + this.props.formData.paymentMethodType + '"}],"receiptNumber":"' + this.props.formData.stornoReceiptData.documentNo + '" , receiptDateTime: "' + this.props.formData.stornoReceiptData.documentDate + '" , fiscalMemorySerialNumber: "' + this.props.formData.stornoReceiptData.fiscalMemorySerialNumber + '" , reason: "' + this.props.formData.stornoReason + '"}}';
        }

        client.post(url + '/reversalreceipt', JSON.parse(body))
            .then(result => result.data)
            .then(result => {
                if (result.ok === false) {
                    showAToast(plainTranslate(this.props.settings.locale, result.messages[0].text), 'errSettings', 'error');
                } else {
                    result.receipt = body;
                    this.props.change('receiptData', result);
                    this.save();
                    this.setState({ submitting: false });
                }
            });
    }

    printReceipt(url) {
        let items = [];
        let discountRate = 0;

        let paymentAmount = 0;
        let multiplier = "e+2";
        let multiplier2 = "e-2";

        this.props.formData.sale && this.props.formData.lines.map(line => {
            let discountAmount = 0;
            let item = {};
            let price = parseFloat(Math.sign((line.unitPrice * (line.taxRate / 100)) + line.unitPrice) * (Math.round(Math.abs((line.unitPrice * (line.taxRate / 100)) + line.unitPrice) + multiplier) + multiplier2));
            let lineDiscountAmount = 0;

            item.text = line.itTitle ? line.itTitle : line.itemDescription;
            item.quantity = line.quantity;

            item.taxGroup = 2;
            let comment = {};
            if (line.discountRate) {
                discountRate += Number(line.discountRate);
                lineDiscountAmount = parseFloat(Math.sign(price * (Number(line.discountRate) / 100)) * (Math.round(Math.abs(price * (Number(line.discountRate) / 100)) + multiplier) + multiplier2));
                discountAmount += price * (line.discountRate / 100);

                let totalDiscountAmount = parseFloat(Math.sign(discountAmount) * (Math.round(Math.abs(discountAmount) + multiplier) + multiplier2));
                totalDiscountAmount = parseFloat(Math.sign(item.quantity * totalDiscountAmount) * (Math.round(Math.abs(item.quantity * totalDiscountAmount) + multiplier) + multiplier2));
                var res = totalDiscountAmount.toString().split(".");

                if (res.length == 1 || res[1].length < 3) {
                    totalDiscountAmount = totalDiscountAmount.toFixed(2);
                }

                comment.type = "comment";
                comment.text = "Отстъпка " + line.discountRate + "%: " + totalDiscountAmount;
            }

            item.unitPrice = parseFloat(Math.sign(price - lineDiscountAmount) * (Math.round(Math.abs(price - lineDiscountAmount) + multiplier) + multiplier2));

            items.push(item);
            if (line.discountRate) {
                items.push(comment);
            }
        });

        if (this.props.formData.sale && this.props.formData.sale.amountDiscount > 0) {
            let totalDiscount = {};
            let footerComment = {};

            totalDiscount.type = "discount-amount";
            totalDiscount.amount = this.props.formData.sale.amountDiscount;

            items.push(totalDiscount);

            footerComment.type = "footer-comment";
            footerComment.text = "Вие и Вашият любимец получихте " + this.props.formData.sale.totalDiscountRate + "% отстъпка";

            items.push(footerComment);
        }

        let paymentName = null;
        this.props.formProperties.form.fields.map(field => {
            if (field.id === 'payId') {
                let paymentMethod = field.options.find(x => x.value === this.props.formData.payId);
                paymentName = paymentMethod.label;
            }
        })
        let body = null;

        let amountOriginal = this.props.formData.amountOriginal;

        if (amountOriginal < this.props.formData.documentAmount) {
            body = '{"uniqueSaleNumber": "' + this.props.formData.sale.documentExtNo + '","items": [{"text":"' + paymentName + '", "unitPrice":' + amountOriginal + ', "taxGroup": 2}],"payments": [{"amount": ' + this.props.formData.amountOriginal + ',"paymentType": "' + this.props.formData.paymentMethodType + '"}]}';
        } else {
            body = '{"uniqueSaleNumber": "' + this.props.formData.sale.documentExtNo + '","items": ' + JSON.stringify(items) + ',"payments": [{"amount": ' + this.props.formData.amountOriginal + ',"paymentType": "' + this.props.formData.paymentMethodType + '"}]}';
        }

        client.post(url + '/receipt', JSON.parse(body))
            .then(result => result.data)
            .then(result => {
                if (result.ok === false) {
                    showAToast(plainTranslate(this.props.settings.locale, result.messages[0].text), 'errSettings', 'error');
                } else {
                    result.receipt = body;
                    this.props.change('receiptData', result);
                    this.save();
                    this.setState({ submitting: false });
                }
            });

    }

    checkDeviceAndPrint(saveNew, issue) {
        this.setState({ submitting: true });
        if (this.props.formData.isFiscalDevice === true) {
            client.post('/' + this.props.match.params.db + this.props.checkDevices, {
                workplace: localStorage.workplace
            })
                .then(result => result.data)
                .then(result => {
                    if (result.url) {
                        client.get(result.url + '/status')
                            .then(device => device.data)
                            .then(device => {
                                let deviceStatus = '';
                                if (device.ok === true) {
                                    deviceStatus = 'connected';
                                } else {
                                    deviceStatus = 'not connected';
                                }

                                if (deviceStatus === "connected") {
                                    let response = null;
                                    if (this.props.printReceipt) {
                                        if (this.props.formData.reversalId === null) {
                                            this.printReceipt(result.url);
                                        } else {
                                            this.printStorno(result.url);
                                        }
                                    }
                                } else {
                                    showAToast(plainTranslate(this.props.settings.locale, 'Fiscal Device is not connected!'), 'errSettings', 'error');
                                }
                            })
                    } else {
                        //this.save(saveNew, issue);
                        showAToast(plainTranslate(this.props.settings.locale, 'Add a fiscal device to this workplace!'), 'errSettings', 'error');
                    }
                })
        } else {
            this.save(saveNew, issue);
        }


    }

    redirectOnClick(url) {
        Modal.destroyAll();
        this.props.history.push(url);
    }

    reportSaveAs(isNew, isPublic) {
        if (isNew) {
            this.setState({ reportName: true });
        } else {
            this.props.formData.isPublic = isPublic;
            this.save(true);
        }
    }

    disableSave(value) {
        this.setState({ submitting: value });
    }

    save(saveNew, issue, print, formSaveRoute = null) {
        this.setState({ submitting: true });
        let form;

        if (this.props.saveReport) {
            if (saveNew) {
                this.saveRoute = this.props.saveRoute + '?type=' + this.props.type;
            } else {
                this.saveRoute = this.props.saveRoute;
            }
            this.props.onSidebarMount(this.props.settings.sidebarRoute);
        }
        if (issue) {
            form = { ...this.props.formData, status: 'issued' };
        } else {
            if (localStorage.workplace !== 'undefined') {
                form = { ...this.props.formData, workplace: localStorage.workplace };
            } else {
                form = this.props.formData;
            }
            if (this.props.custFormData) {
                form = { ...this.props.formData, locId: this.props.custFormData.locId };
            }
        }
        if (this.props.isReport) {
            form['startDate'] = this.props.startDate;
            form['endDate'] = this.props.endDate;
        }

        this.props.save(this.props.form, formSaveRoute ? formSaveRoute : this.saveRoute, form)
            .then((response) => {
                this.setState({ submitting: false });
                if (response && typeof response !== 'string') {
                    if ('errors' in response) {
                        showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                    } else {
                        showAToast(plainTranslate(this.props.settings.locale, 'Data saved successfully!'), 'succSettings', 'success');

                        if ('documentNumberChanged' in response) {
                            Modal.warning({
                                title: plainTranslate(this.props.settings.locale, response.documentNumberChanged.title),
                                content: (
                                    <div>
                                        <p>{plainTranslate(this.props.settings.locale, 'Please check your')} <a style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={() => this.redirectOnClick(response.documentNumberChanged.redirectUrl)}>{plainTranslate(this.props.settings.locale, 'document sequence')}</a></p>
                                    </div>
                                )
                            });

                        }
                        if (this.props.updateOnSave && !response.redirect && !response.keepFormData) {
                            this.updateForm();
                        }

                        if (print) { 
                            if (this.props.download) {
                                this.download =  '/' + this.props.match.params.db + this.props.download + response.entity.id + '/export.pdf';
                                window.open(this.download, "_blank");
                            }
                        }
                        if (this.props.onSuccessfulSave) {
                            this.props.onSuccessfulSave(response);
                        }

                        if (!this.props.popup && !this.props.gridpopup) {
                            if (response.redirect) {
                                this.props.history.push(response.redirectUrl);
                            } else {
                                if (!this.props.formProperties.isEdit) {
                                    if (!saveNew) {
                                        this.props.history.push(response.redirectUrl);
                                    } else {console.log('test');
                                        this.props.reset();
                                    }
                                }
                            }
                        }
                        if (response.reloadHeader) {
                            this.props.onSidebarMount(this.props.settings.sidebarRoute);
                        }

                    }
                } else if(response && typeof response === 'string'){
               //     showAToast(plainTranslate(this.props.settings.locale, response), 'errSettings', 'error');
                }else {
                  //  showAToast(plainTranslate(this.props.settings.locale, "An error has occurred"), 'errSettings', 'error');
                }
            });
    }

    apply() {
        let form;
        form = this.props.formData;
        this.setState({ submitting: true });
        if (this.props.isPos) {
            form['isPos'] = true;
        }
        if (this.props.isReport) {
            form['startDate'] = this.props.startDate;
            form['endDate'] = this.props.endDate;
        }
        this.props.save(this.props.form, this.props.saveReport ? this.props.filtersSaveRoute : this.saveRoute, form)
            .then((response) => {
                this.setState({ submitting: false });
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                } else {
                    if (this.props.onSuccessfulSave) {
                        this.props.onSuccessfulSave(response);
                    }
                    if (!this.props.applyFilters) {
                        this.props.history.push(response.redirectUrl, { response: response });
                    }
                }
            });
    }

    render() {
        let { handleSubmit, formProperties } = this.props;
        
        const fields = formProperties.form && formProperties.form.fields ? formProperties.form.fields : [];
        let returnUrl = formProperties.form ? formProperties.form.returnUrl : null;
        returnUrl = returnUrl ? returnUrl : '#';

        let linkTitle = formProperties.form && formProperties.form.linkTitle ? formProperties.form.linkTitle.label.join(' ') : '';
        let urlLinkTitle = formProperties.form && formProperties.form.linkTitle ? formProperties.form.linkTitle.url : '#';
        let title = formProperties.form && formProperties.form.title ? formProperties.form.title.join(' ') : '';
        title = plainTranslate(this.props.settings.locale, title);
        let showPrintSave = false;
        if (localStorage.workplace !== 'undefined' && this.props.formData && this.props.formData.isFiscalDevice !== undefined && this.props.formData.isFiscalDevice === true) {
            showPrintSave = true;
        }

        const CustomForm = components[this.props.customForm];

        let disableSave = false;

        if (this.props.formData && this.props.formData.hasOwnProperty('disableSave')) {
            disableSave = this.props.formData.disableSave;
        }

        return (
            <div>
                <div className="dashboard">
                    {this.props.popup !== true && (!this.props.customForm || this.props.customForm !== 'pos') &&
                        <div className="moduleSubMenu backMenu">
                            <div className="col-sm-12">
                                <h3 className="pull-left">
                                    <Link to={returnUrl}>
                                        {!this.props.popup && !this.props.isReport && <i className="ion-ios-arrow-back"></i>}</Link> {!formProperties.showLoader && !linkTitle && title}
                                    {!formProperties.showLoader && linkTitle &&
                                        <Link to={urlLinkTitle}>
                                            {linkTitle}
                                        </Link>
                                    }
                                </h3>

                                {
                                    formProperties.form && formProperties.form.tabs &&
                                    <ul className="nav navbar-nav pull-right">
                                        {
                                            formProperties.form.tabs.map((tab, key) => {
                                                return <li className="nav-item" key={key}>
                                                    <Link className={tab.active ? 'active' : ''} to={tab.link}>
                                                        {plainTranslate(this.props.settings.locale, tab.label)}
                                                    </Link>
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    }
                </div>
                {this.props.customForm ?
                    <CustomForm
                        fields={fields}
                        change={this.changeFieldValue}
                        formData={this.props.formData}
                        formProperties={this.props.formProperties}
                        printAndSave={this.checkDeviceAndPrint}
                        handleSubmit={handleSubmit}
                        enableSave={!this.props.invalid && !this.state.submitting}
                        save={(saveNew, issue) => this.save(saveNew, issue)}
                        apply={this.apply}
                        showCancel={this.props.popup}
                        applyButton={this.props.applyButton}
                        showSaveNew={!formProperties.hideSaveNew && !formProperties.isEdit && !this.props.popup}
                        issueButton={this.props.issueButton}
                        cancel={this.props.onCancel}
                        submitting={this.state.submitting}
                    />
                    :
                    <div id="reactForm">
                        <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                            <div className="col-lg-12">
                                <section className={formProperties.form && formProperties.form.noBackground ? '' : 'panel'} style={{ display: formProperties.form && formProperties.form.noBackground ? '' : 'block' }}>
                                    <header className="panel-heading"
                                        style={{ display: formProperties.form && formProperties.form.noBackground ? 'none' : 'block' }}>{plainTranslate(this.props.settings.locale, formProperties.showLoader ? '...' : formProperties.isEdit ? 'Edit' : 'New')}</header>

                                    {
                                        !formProperties.showLoader &&
                                        <div className="panel-body"
                                            style={formProperties.form && 'panelBody' in formProperties.form ? { width: formProperties.form.panelBody } : {}}>

                                            {
                                                formProperties.form && formProperties.form.breadcrumb && formProperties.form.breadcrumb.fields.length > 0 &&
                                                <div className="status-container col-md-12 col-xs-12">
                                                    <div className={formProperties.form.breadcrumb.disabled ? "status-breadcrumb disabled-breadcrumb" : "status-breadcrumb"}>
                                                        {
                                                            formProperties.form.breadcrumb.fields.map((element, key) => {
                                                                return <a 
                                                                    className={formProperties.form.breadcrumb.target in this.props.formData && element.value === this.props.formData[formProperties.form.breadcrumb.target] && element.forbiddenAccess ? 
                                                                        'active forbidden' : formProperties.form.breadcrumb.target in this.props.formData && element.value === this.props.formData[formProperties.form.breadcrumb.target] ? 
                                                                        'active' : element.forbiddenAccess ? 'forbidden' : ''}
                                                                    href="javascript:;" key={key}
                                                                    onClick={() => this.onBreadcrumbClick(element, formProperties.form.breadcrumb.target)}>
                                                                    <span className="breadcrumb__inner">
                                                                        <span className="breadcrumb__desc">{element.label}</span>
                                                                    </span>
                                                                </a>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            <form onSubmit={handleSubmit}>
                                                {
                                                    fields.map((field, key) => {
                                                        return <div key={key}>
                                                            <FormField
                                                                locale={this.props.settings.locale}
                                                                name={field.id}
                                                                label={field.label}
                                                                field={field}
                                                                fields={formProperties.form.fields}
                                                                history={this.props.history}
                                                                component={Field}
                                                                change={this.changeFieldValue}
                                                                type={field.type}
                                                                hide={field.hide}
                                                                formData={this.props.formData}
                                                                placeholder={field.label}
                                                                disableSave={(value) => this.disableSave(value)}
                                                                save={(saveNew, issue, print, route) => this.save(saveNew, issue, print, route)}
                                                                db={this.props.settings.db}
                                                                updateForm={this.updateForm}
                                                                onSuccessfulSave={this.props.onSuccessfulSave}
                                                                gridpopup={this.props.gridpopup}
                                                                closePopup={this.props.closePopup}
                                                                isSubmitting={this.state.submitting}
                                                                isReport={this.props.isReport}
                                                            />
                                                        </div>
                                                    })
                                                }
                                            </form>
                                            {!this.props.notSaveButton &&
                                                <div className="col-md-12 col-xs-12">
                                                    <div className="pull-left">
                                                        <SaveCancel
                                                            locale={this.props.settings.locale}
                                                            enableSave={!this.props.invalid && !this.state.submitting && !disableSave}
                                                            save={(saveNew, issue, print, route) => this.props.checkDevices ? this.checkDeviceAndPrint(saveNew, issue) : this.save(saveNew, issue, print, route)}
                                                            apply={this.apply}
                                                            reportSaveAs={this.reportSaveAs}
                                                            showPrintSave={showPrintSave}
                                                            printAndSave={this.checkDeviceAndPrint}
                                                            showCancel={this.props.popup}
                                                            applyButton={this.props.applyButton}
                                                            download={this.props.download}
                                                            saveReport={this.props.saveReport}
                                                            reportId={this.props.type}
                                                            reportUserId={this.props.formData && this.props.formData.userId ? this.props.formData.userId : null}
                                                            isAdmin={this.props.settings.roles.includes('ROLE_SUPER_ADMIN') ? true : false}
                                                            userId={this.props.settings.user ? this.props.settings.user.id : null}
                                                            showSaveNew={!formProperties.hideSaveNew && !formProperties.isEdit && !this.props.popup}
                                                            issueButton={this.props.issueButton}
                                                            sendButton={this.props.sendButton}
                                                            cancelButton={this.props.cancelButton}
                                                            cancel={this.props.onCancel}
                                                            submitting={this.state.submitting}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.state.reportName &&
                                                <div className="col-md-12 col-xs-12">
                                                    <div className="col-md-7 col-xs-7">
                                                        <label style={{ "paddingTop": 20 }} for="reportName">{plainTranslate(this.props.settings.locale, 'Report name:')}</label>
                                                        <input style={{ border: "1px solid #ccc", "marginTop": 13 }} id="reportName" name="reportName" onChange={(e) => this.changeFieldValue('reportName', e.target.value)} type="text" />
                                                    </div>
                                                    <div className="col-md-1 col-xs-1">
                                                    </div>
                                                    {this.props.settings.roles.includes('ROLE_SUPER_ADMIN') &&
                                                        <div className="col-md-1 col-xs-1">
                                                            <label style={{ paddingTop: 20 }}>{plainTranslate(this.props.settings.locale, 'Is Public?')}</label>
                                                            <Switch style={{ marginTop: 20 }} onChange={(e) => this.changeFieldValue('isPublic', e)} />
                                                        </div>
                                                    }
                                                    <div style={{ marginTop: 40 }}>
                                                        <SaveCancel
                                                            locale={this.props.settings.locale}
                                                            enableSave={!this.props.invalid && !this.state.submitting}
                                                            save={(saveNew, issue) => this.props.checkDevices ? this.checkDeviceAndPrint(saveNew, issue) : this.save(saveNew, issue)}
                                                            reportSaveAs={this.reportSaveAs}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        formProperties.showLoader &&
                                        <FormLoader />
                                    }
                                </section>
                            </div>
                        </div>
                    </div>

                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    formProperties: state.formProperties && state.formProperties[ownProps.form] ? state.formProperties[ownProps.form] : [],
    formData: state.form && state.form[ownProps.form] ? state.form[ownProps.form].values : [],
    initialValues: state.formData ? state.formData[ownProps.form] : [],
});

const mapDispatchToProps = dispatch => ({
    onLoadForm: (name, route) => dispatch(onLoadForm(name, route)),
    onImportLoadForm: (name, db, importData, url) => dispatch(onImportLoadForm(name, db, importData, url)),
    save: (formId, route, data) => dispatch(onSave(formId, route, data)),
    onSidebarMount: (route) => dispatch(onSidebarMount(route)),
    onLoader: (formId, value) => dispatch({
        type: FORM_SHOW_LOADER,
        payload: { formId, value }
    })
});


InitializeFromStateForm = reduxForm({
    // form: 'initializeFromState', // a unique identifier for this form
    enableReinitialize: true,
    validate: validate
})(InitializeFromStateForm);

InitializeFromStateForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)
    (InitializeFromStateForm);


export default InitializeFromStateForm;
