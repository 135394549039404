import React, { Component } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { onDocumentProfileFetchData } from '../../actions/actions';
import { Link } from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import GeneralInformation from './general-information';
import InventoryInfo from './inventory-receipts';
import PaymentsInfo from './payments-info';
import './invoiceProfile.css';
import 'antd/lib/table/style/index.css';
import Select from 'react-select';
import { Table, Button, Tooltip, Menu, Dropdown, Drawer } from 'antd';
import { EditOutlined, FormOutlined, FilePdfOutlined, SnippetsOutlined } from '@ant-design/icons'
import EditablePrint from '../custom-pages/editablePrint.jsx';
import client from '../../actions/client';
import NewForm from "../redux-forms/form";
import Popup from "../common/popup";
import showAToast from '../common/showAToast';

class ExpensesProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template: null, disableButton: false
        };
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.update = this.update.bind(this);
        this.createAccounting = this.createAccounting.bind(this);
    }

    componentDidMount() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/expense/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({ template: this.props.expensesProfileInformation.invoiceProfileInfo.defaultTemplate ? this.props.expensesProfileInformation.invoiceProfileInfo.defaultTemplate.id : null })
        });;

        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.setState({ active: localStorage.getItem('activeExpenseProfileTab') ? localStorage.getItem('activeExpenseProfileTab') : 'expense' });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.update();
        }
    }

    changeTab(tab) {
        localStorage.setItem('activeExpenseProfileTab', tab);

        this.setState({ active: tab });
    }

    handleTemplateChange(e) {
        this.setState({ template: e.value });
    }

    update() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/expense/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({ disableButton: false });
        });
    }

    createAccounting(id) {
        this.setState({ disableButton: true });
        let addVal = {};
        addVal['body'] = id;
        client.post('/' + this.props.match.params.db + '/api/expenses/transfer/to/accounting/' + this.state.accountingTemplate, addVal)
            .then(response => response.data)
            .then(response => {
                if (response['success']) {
                    showAToast(response.success, 'baseSuccess', 'success');   
                } else {
                    showAToast(response.errors, 'baseError', 'error');
                }
                this.setState({
                    accountingTemplate: false
                });
                this.update();
            })
    }

    render() {
        let roles = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.roles : [];
        let docNo = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.docNo : '';
        let currencySign = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.currency : null;
        let stages = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.stages : '';
        let expense = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.expense : '';
        let tableData = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.expenseLines : '';
        let inventories = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.expenseReceipts : '';
        let payments = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.payments : [];
        let paymentTypes = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.paymentTypes : [];
        let paymentMethods = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.paymentMethods : [];
        let templates = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.templates : [];
        let editTemplates = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.editTemplates : [];
        let ledger = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.ledger : null;
        let accountingOptions = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.accOptions : null;
        let isAccounting = this.props.expensesProfileInformation.invoiceProfileInfo ? this.props.expensesProfileInformation.invoiceProfileInfo.isAccounting : null;

        if (this.props.expensesProfileInformation.loading && !this.props.expensesProfileInformation.invoiceProfileInfo) {
            return <FormLoader />
        }

        const columns = [
            {
                'key': 'item',
                'dataIndex': 'item',
                'title': plainTranslate(this.props.settings.locale, 'Item'),
            },
            {
                'key': 'description',
                'dataIndex': 'description',
                'title': plainTranslate(this.props.settings.locale, 'Description'),
            },
            {
                'key': 'quantity',
                'dataIndex': 'quantity',
                'title': plainTranslate(this.props.settings.locale, 'Quantity'),
                align: 'right',
            },
            {
                'key': 'amount',
                'dataIndex': 'amount',
                'title': plainTranslate(this.props.settings.locale, 'Amount'),
                align: 'right',
            },
        ];

        let templatesOptions = [];
        if (templates) {
            templatesOptions = (
                <Menu>
                    {templates.map((option, i) => {
                        return <Menu.Item key={i}>
                            <a href={'/' + this.props.match.params.db + '/expenses/' + this.props.match.params.id + '/template/' + option.value + '/export.pdf'} target="_blank">
                                <Translate locale={this.props.settings.locale} value={option.label} />
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        let editTemplatesOptions = [];
        if (editTemplates) {
            editTemplatesOptions = (
                <Menu>
                    {editTemplates.map((option, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ showEditTemplate: true, templateId: option.value })}>
                                <Translate locale={this.props.settings.locale} value={option.label} />
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }
        let accOptionsMenu;
        if (accountingOptions) {
            accOptionsMenu = (
                <Menu>
                    {accountingOptions.map((option, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ accountingTemplate: option['id'] })}>
                                <Translate locale={this.props.settings.locale} value={option['name']} />
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }


        return (
            <div className="invoice-profile item-profile">
                <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                    <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                        <Link to={"/" + this.props.match.params.db + "/expenses"}>
                            <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Expenses')}
                        </Link>
                    </div>
                    {window.innerWidth < 780 &&
                        <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                            <div className="col-md-12" style={{ margin: "10px 0 30px 0", display: 'flex', justifyContent: 'end' }}>
                                {roles.expense && roles.expense.hasEdit === true &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit')}>
                                        <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Link to={"/" + this.props.match.params.db + "/expenses/" + this.props.match.params.id + '/edit'}>
                                                <EditOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                }
                                {roles.expense && roles.expense.hasEdit === true &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit-beta')}>
                                        <Button type="default" ghost style={{ color: 'green', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Link to={"/" + this.props.match.params.db + "/expense-new/" + this.props.match.params.id + '/edit'}>
                                                <FormOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                }
                                {this.state.template &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Download')}>
                                        <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Dropdown overlay={templatesOptions}>
                                                <Link to="#" data-toggle="dropdown" title="Download">
                                                    <FilePdfOutlined />
                                                </Link>
                                            </Dropdown>
                                        </Button>
                                    </Tooltip>
                                }
                                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Editable templates')}>
                                    <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                        <Dropdown overlay={editTemplatesOptions}>
                                            <Link to="#" data-toggle="dropdown" title="Editable template">
                                                <SnippetsOutlined />
                                            </Link>
                                        </Dropdown>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    }

                    <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                            <GeneralInformation
                                roles={roles.crm}
                                document={expense}
                                docNo={docNo}
                                stages={stages}
                                currency={currencySign}
                                update={this.update}
                                changeStageUrl = {'/' + this.props.match.params.db + '/api/expenses/'}
                                inventories={inventories}
                                type="expense"
                            />
                            <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter" />
                            <div className="account-profile-other-information">
                                <PaymentsInfo roles={roles.payments} sale={expense} payments={payments} paymentMethods={paymentMethods} paymentTypes={paymentTypes} />
                                <InventoryInfo inventories={inventories} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                        {window.innerWidth > 780 &&
                            <div className="col-md-12" style={{ margin: "10px 0 30px 0", display: 'flex', justifyContent: 'end' }}>
                                {roles.expense && roles.expense.hasEdit === true &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit')}>
                                        <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Link to={"/" + this.props.match.params.db + "/expenses/" + this.props.match.params.id + '/edit'}>
                                                <EditOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                }
                                {roles.expense && roles.expense.hasEdit === true &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit-beta')}>
                                        <Button type="default" ghost style={{ color: 'green', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Link to={"/" + this.props.match.params.db + "/expense-new/" + this.props.match.params.id + '/edit'}>
                                                <FormOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                }
                                {this.state.template &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Download')}>
                                        <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Dropdown overlay={templatesOptions}>
                                                <Link to="#" data-toggle="dropdown" title="Download">
                                                    <FilePdfOutlined />
                                                </Link>
                                            </Dropdown>
                                        </Button>
                                    </Tooltip>
                                }
                                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Editable templates')}>
                                    <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                        <Dropdown overlay={editTemplatesOptions}>
                                            <Link to="#" data-toggle="dropdown" title="Editable template">
                                                <SnippetsOutlined />
                                            </Link>
                                        </Dropdown>
                                    </Button>
                                </Tooltip>
                            </div>
                        }
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutter sale-tabs">
                                {window.innerWidth > 780 &&
                                    <button className={this.state.active == 'expense' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('expense')}>
                                        {plainTranslate(this.props.settings.locale, 'Expense')}
                                    </button>
                                }
                                <button className={this.state.active == 'expenseLines' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('expenseLines')}>
                                    {plainTranslate(this.props.settings.locale, 'Expense lines')}
                                </button>
                                {isAccounting && roles.ledger && roles.ledger.hasView === true &&
                                    <button className={this.state.active == 'ledgerEntry' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('ledgerEntry')}>
                                        {plainTranslate(this.props.settings.locale, 'Ledger entry')}
                                    </button>
                                }

                                {this.state.active === 'expense' && window.innerWidth > 780 &&
                                    <div style={{ width: 250, float: 'right', paddingBottom: 10 }}>
                                        <Select
                                            onChange={this.handleTemplateChange}
                                            onBlurResetsInput={false}
                                            onCloseResetsInput={false}
                                            menuPlacement={"auto"}
                                            options={templates}
                                            value={templates ? templates.find(o => o.value === this.state.template) : null}
                                        />
                                    </div>
                                }
                            </div>
                            {this.state.active === "expense" &&
                                <div>
                                    {this.state.template && <embed src={'/' + this.props.match.params.db + '/expenses/'+this.props.match.params.id+'/template/'+this.state.template+'/export.pdf'} type="application/pdf"   height="775px" width="100%"/>}
                                </div>
                            }
                            {this.state.active === 'expenseLines' &&
                                <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                    <Table rowKey={record => record.id} columns={columns} dataSource={tableData} />
                                </div>
                            }
                            {isAccounting && roles.ledger && roles.ledger.hasView === true && this.state.active === 'ledgerEntry' &&
                                <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                    {ledger && !this.state.disableButton ?
                                        <>
                                            <NewForm
                                                formRoute={'/' + this.props.match.params.db + '/api/new-ledger/'}
                                                saveRoute={'/' + this.props.match.params.db + '/api/new-ledger/'}
                                                match={{ params: { id: ledger } }}
                                                type={this.state.formId}
                                                isNew={true}
                                                popup={true}
                                                form={'initializeFromState2'}
                                            />
                                        </>
                                        :
                                        <>
                                            {accountingOptions && !this.state.disableButton &&
                                                <>
                                                    <Dropdown overlay={accOptionsMenu} >
                                                        <Button style={{ margin: '30px' }}>{plainTranslate(this.props.settings.locale, 'Add ledger')}</Button>
                                                    </Dropdown>
                                                </>
                                            }
                                            {!accountingOptions && !this.state.disableButton &&
                                                <><Link style={{ fontSize: '20px', margin: '30px' }} to={'/' + this.props.match.params.db + '/form-types'}>{plainTranslate(this.props.settings.locale, 'No accounting templates. Click here to add some')}</Link></>
                                            }
                                            {this.state.disableButton &&
                                                <FormLoader />
                                            }
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {this.state.accountingTemplate && !this.state.disableButton &&
                        <Popup locale={this.props.settings.locale}
                            title={plainTranslate(this.props.settings.locale, 'Create ledger')}
                            onClose={() => this.setState({ accountingTemplate: false })}>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <div className="text-center">
                                            <h3>
                                                <Translate locale={this.props.settings.locale} value="Are you sure you want to create accounting ledger?" />
                                            </h3>
                                            <button className="button-outlines green text-center"
                                                onClick={() => this.setState({ accountingTemplate: false })}>
                                                <Translate locale={this.props.settings.locale} value="No" />
                                            </button>
                                            <button className="button-outlines text-center btn-ok"
                                                onClick={() => this.createAccounting(expense.id)}>
                                                <Translate locale={this.props.settings.locale} value="Yes" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Popup>
                    }
                </div>
                <Drawer className="grid-popup-form" title="Basic Drawer" placement="right" visible={this.state.showEditTemplate} width={'90%'} onClose={() => this.setState({ showEditTemplate: false })}>
                    <EditablePrint {...this.props} type={"expense"} db={this.props.match.params.db} templateId={this.state.templateId} docId={this.props.match.params.id} />
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    expensesProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesProfile);
