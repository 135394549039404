import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Table, Switch, Button, Radio, Select } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, TableOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import Translate, { plainTranslate } from '../common/translate';
import {Link} from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

import {
    onGridMount,
    gridV2FetchData
} from "../../actions/actions";

const { Option } = Select;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const Columns = (props, state) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {

  }, []);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  function onVisibleChange(checked,val) {
    let index = data.findIndex(o => o.dataIndex === val);
    data[index].hide = !checked;
    setData([...data]);
  }

  const onChangeColumns = () =>{
    props.onChangeColumns(data);
    setVisible(false);
  }

  const onRadioButtonChange = (e) => {
    let index = data.findIndex(o => o.dataIndex === e.target.name);
    data[index].width = e.target.value;
    setData([...data]);
  }

  const onSelectChange = (value, name) => {
    let index = data.findIndex(o => o.dataIndex === name);
    data[index].width = value;
    setData([...data]);
  };


  useEffect(() => {
    setData(props.columns);
  }, [props.columns]);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(data), oldIndex, newIndex).filter(el => !!el);
      setData(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: (text,record)  => record.dataIndex !== 'ID' ? <DragHandle /> : null,
    },
    {
      title: plainTranslate(props.settings.locale, 'Name'),
      dataIndex: 'title',
      className: 'drag-visible',
      render: (text,record) => <span>{plainTranslate(props.settings.locale, record.title)}</span>
    },
    {
      title: plainTranslate(props.settings.locale, 'Size'),
      dataIndex: 'size',
      render: (text, record) => 
        <Radio.Group defaultValue={record.width} onChange={onRadioButtonChange} name={record.dataIndex} size="small">
          <Radio.Button value={100}>XS</Radio.Button>
          <Radio.Button value={200}>S</Radio.Button>
          <Radio.Button value={300}>M</Radio.Button>
          <Radio.Button value={400}>L</Radio.Button>
          <Radio.Button value={500}>XL</Radio.Button>
        </Radio.Group>
    },
    {
      title: plainTranslate(props.settings.locale, 'Show/Hide'),
      dataIndex: 'show',
      render: (text, record) => (record.dataIndex !== 'ID' ? <Switch checked={record.hasOwnProperty('hide') ? !record.hide : true} onChange={(checked, val) => onVisibleChange(checked, record.dataIndex)} /> : null),
    },
  ];

    return (
      <>
      <TableOutlined onClick={showDrawer} />
      <Drawer className={"grid-columns-drawer"} maskClosable={false} width={700} title={plainTranslate(props.settings.locale,"Columns")} placement="right" onClose={onClose} visible={visible} footer={<Button className="button-primary" type="primary" onClick={onChangeColumns}>{plainTranslate(props.settings.locale,"Apply")}</Button>}>
        <Table columns={columns} dataSource={data} pagination={false} 
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}/>
      </Drawer>
      </>
    );
};

const mapStateToProps = state => ({
  settings: state.settings,
  gridProperties: state.gridProperties,
  data: state.gridData.data,
  pinnedRows: state.gridData.pinnedRows,
  pages: state.gridData.pages,
  totalRecords: state.gridData.totalRecords
});

const mapDispatchToProps = dispatch => ({
  gridV2FetchData: (route, filters, signal) => dispatch(gridV2FetchData(route, filters, signal)),
  onGridMount: (route) => dispatch(onGridMount(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Columns);