export default function getGridV2Routes(db) {
    return [
        {
            title: ['Tasks', '›', 'List'],
            path: '/:db/tasks-v2',
            gridRoute: '/api/grid/settings/grid-properties/tasks',
            gridDataRoute: '/api/grid/settings/grid-data/tasks',
            gridExportRoute: '/api/grid/tasks/export/',
            gridSaveRoute: '/api/grid/tasks/save'
        },
        {
            title: ['Sales', '›', 'List'],
            path: '/:db/sales-v2',
            gridRoute: '/api/grid/settings/grid-properties/sales',
            gridDataRoute: '/api/grid/settings/grid-data/sales',
            gridExportRoute: '/api/grid/sales/export/',
            gridSaveRoute: '/api/grid/sales/save'
        },
        {
            title: ['Inventory requests', '›', 'List'],
            path: '/:db/inventory-request-v2',
            gridRoute: '/api/grid/settings/grid-properties/inventory-request',
            gridDataRoute: '/api/grid/settings/grid-data/inventory-request',
            gridExportRoute: '/api/grid/inventory-request/export/',
            gridSaveRoute: '/api/grid/inventory-request/save'
        },
        {
            title: ['Location places', '›', 'List'],
            path: '/:db/administration/location-places',
            gridRoute: '/api/grid/settings/grid-properties/location-places',
            gridDataRoute: '/api/grid/settings/grid-data/location-places',
            gridExportRoute: '/api/grid/location-places/export/',
            gridSaveRoute: '/api/grid/location-places/save'
        },
        {
            title: ['Inventory places', '›', 'List'],
            path: '/:db/inventory-places',
            gridRoute: '/api/grid/settings/grid-properties/inventory-places',
            gridDataRoute: '/api/grid/settings/grid-data/inventory-places',
            gridExportRoute: '/api/grid/inventory-places/export/',
            gridSaveRoute: '/api/grid/inventory-places/save'
        },
        {
            title: ['Projects', '›', 'List'],
            path: '/:db/projects-v2',
            gridRoute: '/api/grid/settings/grid-properties/projects',
            gridDataRoute: '/api/grid/settings/grid-data/projects',
            gridExportRoute: '/api/grid/projects/export/',
            gridSaveRoute: '/api/grid/projects/save'
        },
        {
            title: ['Messages', '›', 'List'],
            path: '/:db/messages',
            gridRoute: '/api/grid/settings/grid-properties/messages',
            gridDataRoute: '/api/grid/settings/grid-data/messages',
            gridExportRoute: '/api/grid/messages/export/',
            gridSaveRoute: '/api/grid/messages/save'
        },
        {
            title: ['Integrations'],
            path: '/:db/settings/integrations',
            gridRoute: '/api/grid/settings/grid-properties/integrations',
            gridDataRoute: '/api/grid/settings/grid-data/integrations',
            gridExportRoute: '/api/grid/integrations/export/',
            gridSaveRoute: '/api/grid/integrations/save'
        },
        {
            title: ['Log Time'],
            path: '/:db/log-time-v2',
            gridRoute: '/api/grid/settings/grid-properties/log-time',
            gridDataRoute: '/api/grid/settings/grid-data/log-time',
            gridExportRoute: '/api/grid/log-time/export/',
            gridSaveRoute: '/api/grid/log-time/save'
        },
        {
            title: ['Items'],
            path: '/:db/administration/items-v2',
            gridRoute: '/api/grid/settings/grid-properties/items',
            gridDataRoute: '/api/grid/settings/grid-data/items',
            gridExportRoute: '/api/grid/items/export/',
            gridSaveRoute: '/api/grid/items/save',
            exportTemplateRoute: '/api/export/importData-v2/items'
        },
        {
            title: ['Accounts'],
            path: '/:db/accounts-v2/:refs',
            gridRoute: '/api/grid/settings/grid-properties/accounts',
            gridDataRoute: '/api/grid/settings/grid-data/accounts',
            gridExportRoute: '/api/grid/accounts/export/',
            gridSaveRoute: '/api/grid/accounts/save'
        },
        {
            title: ['Deals'],
            path: '/:db/deals-v2',
            gridRoute: '/api/grid/settings/grid-properties/deals',
            gridDataRoute: '/api/grid/settings/grid-data/deals',
            gridExportRoute: '/api/grid/deals/export/',
            gridSaveRoute: '/api/grid/deals/save'
        },
        {
            title: ['Deals details'],
            path: '/:db/deal/details-v2',
            gridRoute: '/api/grid/settings/grid-properties/deal-details',
            gridDataRoute: '/api/grid/settings/grid-data/deal-details',
            gridExportRoute: '/api/grid/deal-details/export/',
            gridSaveRoute: '/api/grid/deal-details/save'
        },
        {
            title: ['Ledger'],
            path: '/:db/accounting/ledger-v2',
            gridRoute: '/api/grid/settings/grid-properties/ledger',
            gridDataRoute: '/api/grid/settings/grid-data/ledger',
            gridExportRoute: '/api/grid/ledger/export/',
            gridSaveRoute: '/api/grid/ledger/save'
        },
        {
            title: ['Ledger details'],
            path: '/:db/accounting/ledger-details-v2',
            gridRoute: '/api/grid/settings/grid-properties/ledgerLine',
            gridDataRoute: '/api/grid/settings/grid-data/ledgerLine',
            gridExportRoute: '/api/grid/ledgerLine/export/',
            gridSaveRoute: '/api/grid/ledgerLine/save'
        },
        {
            title: ['Chart of accounts'],
            path: '/:db/accounting/chart-of-accounts-v2',
            gridRoute: '/api/grid/settings/grid-properties/chartOfAccount',
            gridDataRoute: '/api/grid/settings/grid-data/chartOfAccount',
            gridExportRoute: '/api/grid/chartOfAccount/export/',
            gridSaveRoute: '/api/grid/chartOfAccount/save'
        },
        {
            title: ['Journal'],
            path: '/:db/accounting/journal-types-v2',
            gridRoute: '/api/grid/settings/grid-properties/journal',
            gridDataRoute: '/api/grid/settings/grid-data/journal',
            gridExportRoute: '/api/grid/journal/export/',
            gridSaveRoute: '/api/grid/journal/save'
        },
        {
            title: ['Vat sales', '›', 'List'],
            path: '/:db/vat-sales-v2',
            gridRoute: '/api/grid/settings/grid-properties/vatSales',
            gridDataRoute: '/api/grid/settings/grid-data/vatSales',
            gridExportRoute: '/api/grid/vatSales/export/',
            gridSaveRoute: '/api/grid/vatSales/save'
        },
        {
            title: ['Vat purchase', '›', 'List'],
            path: '/:db/vat-purchase-v2',
            gridRoute: '/api/grid/settings/grid-properties/vatPurchase',
            gridDataRoute: '/api/grid/settings/grid-data/vatPurchase',
            gridExportRoute: '/api/grid/vatPurchase/export/',
            gridSaveRoute: '/api/grid/vatPurchase/save'
        },
    ];
}
