import React from 'react';
import {connect} from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import client from '../../../actions/client'

class Tinymce extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(value, editor) {
        this.setState({ value: value });
        this.props.onChange(this.props.id, value);
    };

    imageUpload = (blobInfo, success, failure, progress) => {
        console.log(blobInfo.filename())
        var formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          client
          .post('/' + this.props.db + '/api/settings/image/upload/tinymce', formData)
          .then(res => {
            success(res.data.location)
          })
          .catch(err => {
            failure('HTTP Error: ' + err.response.code);
          })      
      }

    render() {
        return <Editor apiKey='sl1g8x9zoste0gkigvqhdthmcd9s0j9i5x5piq4jzbece53e'
        value={this.state.value}
        init={{
          plugins: 'link image imagetools code lists advlist media emoticons table preview print searchreplace hr wordcount',
          file_picker_types: 'file image media',
        //  images_upload_url: config.API_URL+'/' + this.props.db + '/api/settings/image/upload/tinymce',
          images_upload_handler: this.imageUpload, 
          relative_urls: false,
          toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist outdent indent | undo redo | image code | media',
          forced_root_block : "", 
          force_br_newlines : true,
          force_p_newlines : false
        }}
            onEditorChange={this.onChange}
        />
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Tinymce);

