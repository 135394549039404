import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Menu, Dropdown, Modal, Select,Spin, Drawer } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import { plainTranslate } from '../common/translate';
import { onGridV2BulkActionClick } from "../../actions/actions";
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import client from '../../actions/client';
import showAToast from '../common/showAToast'
import MinQtyTable from "./components/min-quantity-table.jsx";

const { SubMenu } = Menu;
const { Option } = Select;

const BulkActions = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showItemRequestPopup, setShowItemRequestPopup] = useState(null);
  const [showMinQuantityPopup, setShowMinQuantityPopup] = useState(null);
  const [options, setOptions] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [itemRequestStageOptions, setItemRequestStageOptions] = useState(null);
  const [itemRequestStage, setItemRequestStage] = useState(null);
  const [minQuantityData, setMinQuantityData] = useState(null);

  function onCloseModal(){
    setIsModalVisible(false);
    setShowItemRequestPopup(false);
    setShowMinQuantityPopup(false);
  }

  function acceptConfirmDialog(action) {
    let result = null;
    setShowLoader(true);
    if(props.gridSelectAll){
      result = props.deSelectedRows.join();
    }else if(props.selectedRows.length > 0){
      result = props.selectedRows.join();
    }

    let filters = Object.assign({}, props.filters);
    filters.selectAll = props.gridSelectAll;
    if(selectedStage){
      filters.selectedStage = selectedStage;
    }
    if(itemRequestStage){
      filters.itemRequestStage = itemRequestStage;
    }

    delete filters.limit;
    delete filters.offset;
    props.onGridV2BulkActionClick(action.link, result, filters, props.gridId).then((response) => {
      if ('update' in action) {
        props.updateGridData();
      }
      setIsModalVisible(false);
      setSelectedAction(null);
      setSelectedStage(null);
      setShowItemRequestPopup(false);
      setShowLoader(false);
    });
  }

  function onBulkActionClick(action){
    if(action.confirm){
      setIsModalVisible(true);
      setSelectedAction(action);
    }
    else if(action.popup && action.dependencyLink){
        client.post(action.dependencyLink,{ids: props.gridSelectAll ? props.deSelectedRows.join() : props.selectedRows.join(), selectAll: props.gridSelectAll, filters: props.filters, gridId: props.gridId})
        .then(response => response.data)
        .then(response => {
          if(response.redirectUrl){
            props.history.push(response.redirectUrl);
          }else{
            if(action.popup === "itemRequest"){
              let index = props.actions.findIndex(o => o.label === 'Change stage');

              setItemRequestStageOptions(props.actions[index].actions);
              setOptions(action.options);
              setSelectedAction(action);
              setShowItemRequestPopup(true);
            }else if(action.popup === "minQuantity"){
              setMinQuantityData(response);
              setShowMinQuantityPopup(true);
            }
          }
        })
        .catch(err => {
          showAToast(err.response.data.errors, 'baseError', 'error')
        });
    }
    else{
      acceptConfirmDialog(action);
    }
  }

  function handleChange(value, type){
    if(type === 'transfer'){
      setSelectedStage(value);
    }else if(type === 'item-request'){
      setItemRequestStage(value);
    }
  }

  const menu = (<div>
    <Menu selectedKeys={[]} className="bulk-actions-options">
      {props.actions && props.actions.map((action, i) => {
        if ('actions' in action) {
          return <SubMenu key={"submenu"+i} title={plainTranslate(props.settings.locale, action.label)}>
            {action.actions && action.actions.map((option, key) => {
              return <Menu.Item key={'key'+key} onClick={() => onBulkActionClick(option,action.update)} disabled ={option.forbiddenAccess}>
                <span>
                    {plainTranslate(props.settings.locale, option.label)}
                </span>
              </Menu.Item>
            })}
          </SubMenu>
        }else{
          return <Menu.Item key={'menu'+i} onClick={() => onBulkActionClick(action)}>
            <span className={(action.line ? ' text-red' : '')}>
                {plainTranslate(props.settings.locale, action.label)}
            </span>
          </Menu.Item>
        }
      })}
    </Menu>
    {isModalVisible && 
      <Modal visible={isModalVisible} footer={null} width={700} onCancel={onCloseModal}>
        <section className="newPanel">
          <div className="panel-body">
              <div className="row m-bot15">
                  <div className="text-center">
                      <h3><strong>
                          {plainTranslate(props.settings.locale, "Are you sure?")}
                      </strong>
                      </h3>
                      <div className="buttons">
                          <button className="button-outline grey text-center"
                              onClick={onCloseModal}>
                              {plainTranslate(props.settings.locale, "Cancel")}
                          </button>
                          <button className="button-primary text-center btn-ok"
                              onClick={() => acceptConfirmDialog(selectedAction)}>
                              {plainTranslate(props.settings.locale, "Yes")}
                          </button>
                      </div>
                  </div>
              </div>
          </div>
        </section>
      </Modal>
    }
    {showItemRequestPopup && 
      <Modal visible={true} footer={null} width={700} onCancel={onCloseModal}>
        {!showLoader && 
          <section className="newPanel">
          <div className="panel-body">
              <div className="row m-bot15">
                  <div className="text-center">
                  <div>
                    <span style={{marginRight: 20}}>{plainTranslate(props.settings.locale, "Select the stage of transfer receipts")}</span>
                    <Select style={{ width: 120 }} onChange={(value) => handleChange(value, "transfer")} defaultValue={options ? options[0].id : null}>
                      {options.map(option => {
                        return <Option key={option.id} value={option.id}>{option.label}</Option>
                      })}
                    </Select>
                    </div>
                    <div style={{marginTop: 30}}>
                      <span style={{marginRight: 73}}>{plainTranslate(props.settings.locale, "Select the stage of request")}</span>
                      <Select style={{ width: 120 }} onChange={ (value) => handleChange(value, "item-request")} defaultValue={itemRequestStageOptions ? itemRequestStageOptions[0].id : null}>
                        {itemRequestStageOptions.map(option => {
                          return <Option key={option.id} value={option.id}>{option.label}</Option>
                        })}
                      </Select>
                    </div>
                      <div className="buttons">
                          <button className="button-outline grey text-center"
                              onClick={onCloseModal}>
                              {plainTranslate(props.settings.locale, "Cancel")}
                          </button>
                            <button className="button-primary text-center btn-ok"
                              onClick={() => acceptConfirmDialog(selectedAction)}>
                              {plainTranslate(props.settings.locale, "Yes")}
                            </button>
                      </div>
                  </div>
              </div>
          </div>
          </section>
        }
        {showLoader &&
          <div style={{background: "white", height: 200,display: "flex",alignItems: "center",justifyContent: "center"}}>
              <Spin tip="Loading..." size="large">
              </Spin>
          </div>
        }
      </Modal>
    }
    {showMinQuantityPopup && 
      <Drawer visible={true} footer={null} width={1400} onClose={onCloseModal}>
        <MinQtyTable actions={props.actions} data={minQuantityData} filters={props.filters} onClose={onCloseModal} update={props.updateGridData}/>
      </Drawer>
    }
    </div>
  );
  return (
    <Dropdown overlay={menu} className="bulk-actions">
    <a className="ant-dropdown-link " onClick={e => e.preventDefault()}>
      {plainTranslate(props.settings.locale, 'Bulk actions')} <DownOutlined />
    </a>
  </Dropdown>
  );
};

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  onGridV2BulkActionClick: (action, data, filters, gridId) => dispatch(onGridV2BulkActionClick(action, data, filters, gridId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BulkActions));