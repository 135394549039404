import React, {Component} from 'react';
import {render} from "react-dom";
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import {Link} from 'react-router-dom';
import { Select, Table, DatePicker } from 'antd';
import Popup from './../common/popup';
import 'antd/lib/select/style/index.css';
import moment from 'moment';
import showAToast from '../common/showAToast';
import { withRouter } from 'react-router-dom'
import client from '../../actions/client';
import AsyncSelect from 'react-select/lib/Async';

const { Option } = Select;


class GeneralInformation extends Component {
    constructor(props) {
        super(props);

        this.dateFormat = 'DD/M/YYYY';

        this.state= {
            changeStagePopup : false,
            showLoader: false,
            showCancelPopup: false,
            showChangeDocumentDatePopup: false,
            documentDate: null,
            changedStages: {}
        }

        this.markAsPaid = this.markAsPaid.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeOwner = this.changeOwner.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.changeStage = this.changeStage.bind(this);
        this.linkedDocumentsChangeStage = this.linkedDocumentsChangeStage.bind(this);
        this.getLinkedDocumentsContent = this.getLinkedDocumentsContent.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
    }

    markAsPaid(){
        let id = this.props.document.id;
        client.post('/' + this.props.match.params.db + '/api/invoices/paid-status/paid', {'body':id});

        window.location.reload();
    }

    handleChange(value) {
        let disabledDate = false;
        if(this.props.type === 'inventory'){
            disabledDate = this.disabledDate(this.props.document.date);
        }else if(this.props.type === 'expense' && this.props.inventories && this.props.inventories.length > 0){
            disabledDate = this.disabledDate(this.props.document.date);
        }

        this.setState({showLoader: true});
        let index = this.props.stages.findIndex(o => o.id === value);
        if(this.props.stages[index].cancel){
            let body = [];
            body.push(this.props.document.id);
            
            client.post('/' + this.props.match.params.db + '/api/check-documents/sales', {'body': body})
            .then(response => response.data)
            .then(response => {
                if(response.linkedDocuments.length > 0){
                    this.setState({showCancelPopup: true, linkedDocumentsData: response, cancelStageId: value, showLoader: false});
                }else{
                    this.changeStage(value);
                }
            });
        }else if(this.props.stages[index].status && this.props.stages[index].status == 6 && disabledDate){
            this.setState({showChangeDocumentDatePopup: true,showLoader: false});
        }else{
            this.changeStage(value);
        }
    }

    changeStage(value){
        this.setState({showLoader: true});
        let url = this.props.changeStageUrl + this.props.document.id + '/change/stage/' + value;
        let changedStages = this.state.changedStages;

        for (const property in changedStages) {
          client.post('/' + this.props.match.params.db + changedStages[property], {'body':this.props.document.id})
            .then(response => response.data)
            .then(response => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            });
        }
        client.post(url, {'documentDate': this.state.documentDate})
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                this.setState({changeStagePopup: false, showLoader: false})
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                if ('sale' in response || 'receipt' in response || 'expense' in response || 'deal' in response) {
                    this.props.update();
                    this.setState({changeStagePopup : false, showLoader: false, showCancelPopup: false, showChangeDocumentDatePopup: false});
                }
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });
    }

    changeOwner(value) {
        if (value.hasOwnProperty('value')) {
            value = value.value;
        }
        client.get(this.props.changeStageUrl + this.props.document.id + '/change/owner/' + value)
        .then(response => response.data)
        .then(response => {
            this.setState({owner : value.value});
            showAToast(response.success, 'baseSuccess', 'success');
        });
    }

    loadOptions(inputValue, callback) {
        let url = '';
        if (!inputValue) {
            url = url = this.props.asyncUrl;
        } else {
            url = this.props.asyncUrl + '?q=' + inputValue;
        }

        client
        .get(url)
        .then(response => response.data)
        .then(response => {
            let options = [];
            if (response) {
                options = response.map(option => { 
                    return {
                        'value': option.value,
                        'label': option.label + ' (' + option.email + ')'
                    };
                });
            }
            this.setState({options: options});
            callback(options ? options : this.props.options);
        });
    }

    linkedDocumentsChangeStage(value, record) {
        let arr = Object.assign({}, this.state.changedStages);
        arr[record.id] = record.url + value;
        this.setState({changedStages: arr});
    }

    getLinkedDocumentsContent(dateFormat){
        const columns = [{
                title: plainTranslate(this.props.settings.locale, 'Document Type'),
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document Date'),
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => {
                    <p>{moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat)}</p>
                }
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Current Stage'),
                dataIndex: 'stage',
                key: 'stage',
                render: (text, record) => {
                    let stages = [];
                    if(record.type === 'payment'){
                        stages = this.state.linkedDocumentsData.paymentStagesOptions;
                    }else if(record.type === 'inventory receipt'){
                        stages = this.state.linkedDocumentsData.inventoryStagesOptions;
                    }else if(record.type === 'item request'){
                        stages = this.state.linkedDocumentsData.itemRequestStagesOptions;
                    }
                    return <Select defaultValue={record.stage} onChange={(value,type, id) => this.linkedDocumentsChangeStage(value, record)}>
                        {stages.map(stage => {
                            return <Option disabled={stage.position <= record.stagePosition ? true : false} value={stage.value}>{stage.label}</Option>
                        })}
                    </Select>
                }
            }];

        return <div><p>This sale is linked by:</p>
            <p>*choose a status for linked documents</p>
            <Table dataSource={this.state.linkedDocumentsData.linkedDocuments} columns={columns} pagination={false} />
            <p>*if payment document contains more than one document, the line with this sale will be deleted.</p>
            <button className="button-primary-edited text-center btn-ok"
                    onClick={() => this.changeStage(this.state.cancelStageId)}>
                <Translate locale={this.props.settings.locale} value="Confirm"/>
            </button>
            <button className="button-outlines green text-center"
                    onClick={() => this.setState({showCancelPopup: false})}>
                <Translate locale={this.props.settings.locale} value="Cancel"/>
            </button>
            </div>
    }

    onDateChange(date, dateString) {
        this.setState({documentDate: dateString});
    }

    disabledDate(currentDate){
        let current = moment(currentDate, "DD.MM.YYYY");
        return current && !(moment().diff(current, 'months') >= 0 && moment().diff(current, 'months') < this.props.settings.openPeriodBefore) && !(moment().diff(current, 'months') <= 0 && moment().diff(current, 'months') > this.props.settings.openPeriodAfter*-1);
    };

    render() {
        let docData = this.props.document ? this.props.document : {};
        let dateFormat = this.props.settings.dateFormat;
        
        let docNo = this.props.docNo ? this.props.docNo : '';
        let dueDate = null;
        if(docData && docData.dueDate){
            let a = moment(docData.dueDate).format('YYYY-MM-DD');
            let b = moment(new Date()).format('YYYY-MM-DD');

            let diff = moment(a,"YYYY-MM-DD").diff(moment(b,"YYYY-MM-DD"));
            dueDate = moment.duration(diff);

        }
        let account = null;
        let billingData = null;

        let showAmount = true;

        if(this.props.showAmount === false){
            showAmount = false;
        }

        if(docData){
            if ((docData.accId || docData.accountId) && this.props.roles && this.props.roles.hasView == true) {
                if (docData.accId) {
                    account = <Link to={'/' + this.props.match.params.db +'/account/'+ docData.accId +'/profile'} className="link">{docData.accName ? docData.accName : docData.custId ? docData.custId : null}</Link>;
                    if(this.props.type && this.props.type === 'sale' && docData.custIdAdditionalValue){
                        billingData = <Link to={'/' + this.props.match.params.db +'/account/'+ docData.custIdAdditionalValue.accId +'/profile'} className="link">{docData.custId}</Link>;
                    }
                }
                if (docData.accountId) {
                    account = <Link to={'/' + this.props.match.params.db +'/account/'+ docData.accountId +'/profile'} className="link">{docData.accountName ? docData.accountName : docData.custId ? docData.custId : null}</Link>;
                }
            } else {
                if (docData.accId) {
                    account = <h3>{docData.accName ? docData.accName : docData.custId ? docData.custId : null}</h3>;
                    if(this.props.type && this.props.type === 'sale'){
                        billingData = <h3>{docData.custId}</h3>;
                    }
                }
                if (docData.accountId) {
                    account = <h3>{docData.accountName ? docData.accountName : docData.custId ? docData.custId : null}</h3>;
                }

            }
        }

        let paymentStatus = null;
        let paymentClass = null;

        if(docData.paid === 'paid' || docData.paymentStatus == 2){
            paymentStatus = 'paid';
            paymentClass = 'paid';
        }
        if(docData.paid === 'partialy paid' || docData.paymentStatus == 1){
            paymentStatus = 'partialy paid';
            paymentClass = 'partialypaid';
        }
        if(docData.paid == 'unpaid' || docData.paymentStatus == 0){
            paymentStatus = 'unpaid';
            paymentClass = 'unpaid';
        }
        if(docData.paid == 'overpaid' || docData.paymentStatus == 3){
            paymentStatus = 'overpaid';
            paymentClass = 'overpaid';
        }

        return (
            <div className="account-profile-information-general">
                <div className="line col-md-12 col-xs-12 no-gutter">
            {docData.deleted &&
                    <React.Fragment>
                    <div className="col-lg-10 col-md-10 col-xs-10 no-gutter">
                        <h5 className="col-md-12 col-xs-12 infoMessage text-red"><i className="fa fa-ban" aria-hidden="true"></i> {plainTranslate(this.props.settings.locale, "Document is deleted!")}</h5>
                    </div>
                    <div className="clearfix"></div>
                    </React.Fragment>
                }
                {docData.documentType &&
                    <h3 className="invoice-no">{plainTranslate(this.props.settings.locale, docData.documentType)}</h3>
                }
                {docData.no &&
                    <h3 className="invoice-no">{ docData.no }</h3>
                }
                {!this.props.type && docData.documentNo &&
                    <h3 className="invoice-no">{ docNo ? docNo : docData.documentNo }</h3>
                }
                {this.props.type && this.props.type === 'sale' &&
                     <div className="invoice-no">
                        <span style={{fontSize: 16}}>{plainTranslate(this.props.settings.locale, 'Sale No')}</span> <span style={{fontSize: 18}}>{docData.documentNo} </span> 
                        {this.props.invoice && 
                            <span>
                                 / 
                                <span style={{fontSize: 16}}> {plainTranslate(this.props.settings.locale, 'Invoice No')}</span>
                                <span style={{fontSize: 18}}> {this.props.invoice.no}</span>
                            </span>
                        }
                    </div>
                }
                </div>
                <div className="line col-md-12 col-xs-12 no-gutter">
                    {account}
                </div>

                {this.props.type && this.props.type === 'sale' && docData.custIdAdditionalValue && docData.accId !== docData.custIdAdditionalValue.accId &&
                    <div className="line col-md-12 col-xs-12 no-gutter">
                        {billingData}
                    </div>
                }
                <div className="line col-md-12 col-xs-12 no-gutter">
                    <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Status')}:</span></div>
                    <div className="col-lg-8 col-md-8 col-xs-8 content">
                    {this.props.stages ?
                        <Select value={docData.status} onChange={(value) => this.setState({changeStagePopup: value})} style={{ maxWidth: '100%',minWidth: "70%" }} >
                            {this.props.stages.map(stage=>
                                <Option key={stage.id} value={stage.id} disabled = {stage.disabled || stage.forbiddenAccess}>{plainTranslate(this.props.settings.locale, stage.label)}</Option>
                                )}
                              </Select> :
                        <div className="invoice-status">
                            <span className={docData.status}>{plainTranslate(this.props.settings.locale, docData.status)}</span>
                        </div>
                    }
                    </div>
                </div>
                {!docData.docType &&
                <div className="line col-md-12 col-xs-12 no-gutter">
                    <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Payment Status')}:</span></div>
                    <div className="col-lg-8 col-md-8 col-xs-8 content">
                        <div className="payment-status">
                            <div><span className={paymentClass}>{plainTranslate(this.props.settings.locale, paymentStatus)}</span></div>
                        </div>
                    </div>
                </div>
                }
                {showAmount &&
                    <div className="line col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-4 col-md-4 col-xs-4 title">
                            <span>{plainTranslate(this.props.settings.locale, 'Amount')}:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                            <div className="amount">
                                {docData.hasOwnProperty('totalAmount') ?
                                    <span className="font-weight-bold">{parseFloat(docData.totalAmount).toFixed(2)} {this.props.currency}</span>
                                :
                                    <span className="font-weight-bold">{parseFloat(docData.amountSum).toFixed(2)} {this.props.currency}</span>
                                }
                            </div>
                        </div>
                    </div>
                }
                {docData.dueDate && (paymentStatus === 'unpaid' || paymentStatus == 'partialy paid') &&
                <div className="line col-md-12 col-xs-12 no-gutter">
                    <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Due')}:</span></div>
                    <div className="col-lg-8 col-md-8 col-xs-8 content">
                        <div className="due">
                            {console.log(docData.dueDate)}
                           <span>{moment((docData.dueDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat)}</span>
                           {dueDate > 0 &&
                            <span className="dueDate">{dueDate.days()}{plainTranslate(this.props.settings.locale, ' days due')}</span>
                           }
                           {dueDate < 0 &&
                           <span className="dueDate">{dueDate.days()*-1}{plainTranslate(this.props.settings.locale, ' days overdue')}</span>
                            }
                        </div>
                    </div>
                </div>
                }
                {docData.type &&
                    <div className="line col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Type')}:</span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                            <div>
                               <span style={{textTransform: 'capitalize'}}>{plainTranslate(this.props.settings.locale, docData.type)}</span>
                            </div>
                        </div>
                    </div>
                }
                {docData.address &&
                    <div className="line col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Address')}:</span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                            <div>
                               <span>{plainTranslate(this.props.settings.locale, docData.address)}</span>
                            </div>
                        </div>
                    </div>
                }
                {docData.documentRefNo &&
                    <div className="line col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Reference document')}:</span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                            <div>
                               <span>{docData.documentRefNo} / {moment((docData.documentRefDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat)}</span>
                            </div>
                        </div>
                    </div>
                }
                <div className="line col-md-12 col-xs-12 no-gutter">
                    <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Created')}:</span></div>
                    <div className="col-lg-8 col-md-8 col-xs-8 content">
                        <div className="created">
                           {docData.createdAt ? moment((docData.createdAt).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat) : docData.created ? moment((docData.created).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat) : null} by {docData.createdByName ? docData.createdByName : docData.createdBy ? docData.createdBy : null}
                        </div>
                    </div>
                </div>
                {(docData.modifiedByName || docData.modifiedBy) &&
                <div className="line col-md-12 col-xs-12 no-gutter">
                    <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Modified')}:</span></div>
                    <div className="col-lg-8 col-md-8 col-xs-8 content">
                        <div className="created">
                           {docData.modified ? moment((docData.modified).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat) : docData.updatedAt ? moment((docData.updatedAt).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat) : null} by {docData.modifiedByName ? docData.modifiedByName : docData.modifiedBy ? docData.modifiedBy : null}
                        </div>
                    </div>
                </div>
                }
                {this.props.users &&
                    <div className="line col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Owner')}:</span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                        {this.props.asyncUrl ?
                            <AsyncSelect
                                        isClearable
                                        cacheOptions
                                        isSearchable
                                        onChange={this.changeOwner}
                                        onBlurResetsInput={false}
                                        onBlur={event => event.preventDefault()}
                                        onCloseResetsInput={false}
                                        menuPlacement={"auto"}
                                        loadOptions={this.loadOptions}
                                        defaultOptions={this.props.users}
                                        defaultValue={this.state.owner ? this.state.owner : this.props.document ? this.props.document.ownerId : null}
                                    />
                                :
                            <Select  onChange={this.changeOwner} showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} value={this.state.owner ? this.state.owner : this.props.document ? this.props.document.ownerId : null } style={{ maxWidth: '100%',minWidth: "70%" }} >
                                {this.props.users.map(user=>
                                    <Select.Option key={user.id} value={user.id}>{plainTranslate(this.props.settings.locale, user.label)}</Select.Option>
                                )}
                            </Select> 
                        }
                        </div>
                    </div>
                }
                {!this.props.users && this.props.document && this.props.document.ownerName &&
                     <div className="line col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Owner')}:</span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                            <span>{this.props.document.ownerName}</span>
                        </div>
                    </div>
                }

            {
                this.state.changeStagePopup &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Change stage')}
                       onClose={() => this.setState({changeStagePopup: false})} showLoader={this.state.showLoader}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">
                                    <h3>
                                        <Translate locale={this.props.settings.locale} value="Are you sure?"/>
                                    </h3>
                                    <button className="button-outlines green text-center"
                                            onClick={() => this.setState({changeStagePopup: false})}>
                                        <Translate locale={this.props.settings.locale} value="No"/>
                                    </button>
                                    <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.handleChange(this.state.changeStagePopup)}>
                                        <Translate locale={this.props.settings.locale} value="Yes"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            }
            {
                this.state.showCancelPopup &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Change stage')}
                       onClose={() => this.setState({showCancelPopup: false})} showLoader={this.state.showLoader}>
                    <section className="newPanel">
                        <div className="row m-bot15">
                        {this.getLinkedDocumentsContent(dateFormat)}
                        </div>
                    </section>
                </Popup>
            }
            {
                this.state.showChangeDocumentDatePopup &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'To change the stage you must select a date from the open period')}
                       onClose={() => this.setState({showChangeDocumentDatePopup: false})} showLoader={this.state.showLoader}>
                    <section className="newPanel">
                        <div className="row m-bot15" style={{height: 150}}>
                            <div style={{marginLeft: 15}}>
                                <span style={{marginRight: 20, fontSize: 18}}>{plainTranslate(this.props.settings.locale, 'Document Date')}:</span><DatePicker size="large" style={{width: 220}} onChange={this.onDateChange} defaultValue={moment()} format={this.dateFormat} disabledDate={this.disabledDate}/>
                            </div>
                            <div>
                                <button className="button-primary-edited text-center btn-ok"
                                        onClick={() => this.changeStage(this.state.changeStagePopup)}>
                                    <Translate locale={this.props.settings.locale} value="Save"/>
                                </button>
                                <button className="button-outlines green text-center"
                                        onClick={() => this.setState({showChangeDocumentDatePopup: false})}>
                                    <Translate locale={this.props.settings.locale} value="Cancel"/>
                                </button>
                            </div>
                        </div>
                    </section>
                </Popup>
            }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneralInformation));
