import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import ErrorMessage from './errorMessage';
import Text from './types/text';
import Select2 from './types/select2';
import Switch from './types/switch';
import Number from './types/number';
import Rte from './types/rte';
import Tinymce from './types/tinymce';
import Dropdown from './types/dropdown';
import Textarea from './types/textarea';
import Ace from './types/ace';
import InsertTemplate from './types/templateButton';
import Barcode from './types/barcode';
import DatePicker from "./types/datepicker";
import DatepickerCustom from './types/datepickerCustom'
import Image from './types/image';
import File from './types/file';
import Images from './types/images';
import Password from './types/password';
import UserRoles from './types/custom/userRoles';
import TableCollection from './types/tableCollection';
import GroupBox from './types/groupBox';
import TabBox from './types/tabBox';
import Percents from "./types/percents";
import FormFields from "./types/formFields";
import SingleCheckboxes from "./types/singleCheckboxes";
import RolesTable from './types/custom/rolesTable';
import Contacts from './types/contacts';
import TranslationsComponent from './types/translationsComponent';
import AddTranslation from './types/addTranslation';
import AddMeasureConversion from './types/addMeasureConversion';
import TestButton from './types/testButton';
import MultiSelect from './types/multiSelect';
import MultipleSelect from './types/multipleSelect';
import MultipleSelectList from './types/multipleSelectList';
import InformationText from './types/informationText';
import Datalist from './types/datalist';
import Checkmark from './types/checkmark';
import CustomButton from './types/customButton';
import DataTable from './types/dataTable';
import MenuBuilder from './types/menuBuilder';
import CommonLink from './types/commonLink';
import EmailMessage from './types/emailMessage';
import TabTable from './types/tabTable';
import RadioButtons from './types/radioButtons';
import SelectableArrayFields from "./types/selectableArrayFields";
import ListAnalisys from "./types/listAnalisys";
import CustomBlocks from "./types/customBlocks";
import StagesList from "./types/stagesList";
import Markdown from "./types/markdown.jsx";
import ModalForm from "./types/ModalForm.js"
import { Col, Row, Tooltip } from 'antd';
import Attributes from "./types/attributes";
import Sections from './types/sections';
import JsonBuilder from './types/jsonBuilder';
import VisualJsonBuilder from './types/visualJsonBuilder';
import ColorPicker from './types/colorPicker';
import SurveyQuestions from './types/surveyQuestions';

class Field extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.props.field.type === 'switch') {
            this.props.input.onChange(this.props.input.value === true || this.props.input.value === 1);
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.field.hasOwnProperty('linkedField') && prevProps.input.value !== this.props.input.value){
            if(!this.props.formData[this.props.field.linkedField] || prevProps.formData[this.props.field.linkedField] === prevProps.input.value){
                this.props.change(this.props.field.linkedField, this.props.input.value);
            }
        }
    }

    onChangeMainOptions(fieldId, options, group) {
        let arr = (this.props.fields);
        if(group && this.props.formFields){
            let fields = this.props.formFields;
            let groupFields = fields.find(o => o.id === group);
            arr = groupFields ? groupFields.fields : this.props.fields;
        }
        let field = arr ? arr.find(o => o.id === fieldId) : null;

        let optArray = []
        if (options) {
            if(typeof options === 'object'){
                if(typeof options.value !== 'undefined'){
                    optArray.push(options);
                }else{
                    optArray = Object.keys(options).map(key => {               
                        return options[key];
                    })
                }           
            }else{
                optArray = options;
            }
        }
      //console.log(this.props.formFields);console.log(field);
        if (field) {
            if (options && Object.keys(options).length > 0) {
               field.options = optArray;
            } else {
                field.options = [];
            }
        }
    }

    render() {
        return <React.Fragment>{this.props.field.type === 'clearfix' ?
        <div className='clearfix'></div>
        :
        <div className={this.props.field.outerClass ? this.props.field.outerClass  : this.props.field.hide ? 'hidden' : ''}>
            <div className={this.props.field.type === 'hidden' ? 'hide-field' : ''}>
                <div className={this.props.field.width ? this.props.field.width : "field col-md-6 col-xs-12 mobileSelect"}
                     style={{
                         height: 'height' in this.props.field ? this.props.field.height : '',
                         marginTop: 'marginTop' in this.props.field ? this.props.field.marginTop : '',
                     }}>
                    <div
                        className={"row " + ('fieldClass' in this.props.field ? this.props.field.fieldClass : 'm-bot15')}>
                        <div className="form-group"
                             style={{
                                 marginBottom: 'marginBottom' in this.props.field ? this.props.field.marginBottom : ''
                             }}>
                           
                            <label
                                className={"required " + (this.props.field.inlineLabel ? "col-md-8 col-xs-12" : "col-md-12 col-xs-12 no-gutter-m") + " text-left"}
                                style={{visibility: this.props.field.type === 'hidden' ? 'hidden' : 'visible'}}>                               
                                {plainTranslate(this.props.locale, this.props.field.label)}                               
                                {this.props.field.required && <span>*</span>}
                                {this.props.field.help && <span className="help" title={plainTranslate(this.props.locale, this.props.field.help)}><i className="fa fa-question-circle"></i></span>}
                            </label>
                            
                            <div className={this.props.field.inlineLabel ? 'col-md-4 col-xs-12 no-gutter' : this.props.field.type=="tableCollection" ? 'col-md-12 col-xs-12 no-gutter-m no-gutter custom-table' : 'col-md-12 col-xs-12 no-gutter-m'}>
                                {
                                    this.props.field.type === 'text' &&
                                    <Text {...this.props} />
                                }

                                {
                                    this.props.field.type === 'informationText' &&
                                    <InformationText {...this.props} />
                                }
                                
                                {
                                    this.props.field.type === 'checkmark' &&
                                    <Checkmark {...this.props} />
                                }

                                {
                                    this.props.field.type === 'select2' &&
                                    <Select2 {...this.props}  onChangeMainOptions={(field, options, group) => this.onChangeMainOptions(field, options, group)}/>
                                }
                                {
                                    this.props.field.type === 'multiSelect' &&
                                    <MultiSelect {...this.props} />
                                }
                                {
                                    this.props.field.type === 'multipleSelect' &&
                                    <MultipleSelect {...this.props} />
                                }
                                {
                                    this.props.field.type === 'multipleSelectList' &&
                                    <MultipleSelectList {...this.props} />
                                }
                                {
                                    this.props.field.type === 'attributes' &&
                                    <Attributes {...this.props} />
                                }
                                {
                                    this.props.field.type === 'switch' &&
                                    <Switch {...this.props} />
                                }

                                {
                                    this.props.field.type === 'number' &&
                                    <Number {...this.props} />
                                }

                                {
                                    this.props.field.type === 'dropdown' &&
                                    <Dropdown {...this.props} />
                                }

                                {
                                    this.props.field.type === 'textarea' &&
                                    <Textarea {...this.props} />
                                }

                                {
                                    this.props.field.type === 'rte' &&
                                    <Rte {...this.props} />
                                }

                                {
                                    this.props.field.type === 'tinymce' &&
                                    <Tinymce {...this.props} />
                                }

                                {
                                    this.props.field.type === 'ace' &&
                                    <Ace {...this.props} />
                                }
                                
                                {
                                    this.props.field.type === 'menuBuilder' &&
                                    <MenuBuilder {...this.props} />
                                }

                                {
                                    this.props.field.type === 'jsonBuilder' &&
                                    <JsonBuilder {...this.props} />
                                }

                                {
                                    this.props.field.type === 'visualJsonBuilder' &&
                                    <VisualJsonBuilder {...this.props} />
                                }

                                {
                                    this.props.field.type === 'sections' &&
                                    <Sections {...this.props} />
                                }


                                {
                                    this.props.field.type === 'addTranslation' &&
                                    <AddTranslation {...this.props} />
                                }

                                {
                                    this.props.field.type === 'addMeasureConversion' &&
                                    <AddMeasureConversion {...this.props} />
                                }

                                {
                                    this.props.field.type === 'templateButton' &&
                                    <InsertTemplate change={this.props.change} {...this.props} />
                                }

                                {
                                    this.props.field.type === 'testButton' &&
                                    <TestButton change={this.props.change} {...this.props} />
                                }

                                {
                                    this.props.field.id === 'barcode' &&
                                    <Barcode {...this.props} />
                                }

                                {
                                    this.props.field.type === 'datepicker' &&
                                    <DatePicker {...this.props} />
                                }

{
                                    this.props.field.type === 'datepickerCustom' &&
                                    <DatepickerCustom {...this.props} />
                                }

                                {
                                    this.props.field.type === 'image' &&
                                    <Image {...this.props} />

                                }
                                {
                                    this.props.field.type === 'datalist' &&
                                    <Datalist {...this.props} />

                                }
                                {
                                    this.props.field.type === 'file' &&
                                    <File {...this.props} />

                                }

                                {
                                    this.props.field.type === 'password' &&
                                    <Password {...this.props} />

                                }

                                {
                                    this.props.field.type === 'contacts' &&
                                    <Contacts {...this.props} />

                                }

                                {
                                    this.props.field.type === 'translations' &&
                                    <TranslationsComponent {...this.props} />

                                }

                                {
                                    this.props.field.type === 'rolesTable' &&
                                    <RolesTable
                                        {...this.props}
                                        lists={this.props.field.lists}
                                        reports={this.props.field.reports}
                                    />
                                }

                                {
                                    this.props.field.type === 'userRoles' &&
                                    <UserRoles
                                        {...this.props}
                                        locations={this.props.field.locations}
                                        paymentMethods={this.props.field.paymentMethods}
                                        roles={this.props.field.roles}
                                    />
                                }

                                {
                                    this.props.field.type === 'percents' &&
                                    <Percents
                                        {...this.props}
                                        rules={this.props.field.rules}
                                    />
                                }

                                {
                                    this.props.field.type === 'tabBox' &&
                                    <TabBox
                                        {...this.props}
                                        tabs={this.props.field.tabs}
                                        options={this.props.field.options}
                                        additionalData={this.props.field.additionalData}
                                        formData={this.props.formData}
                                        change={this.props.change}
                                    />
                                }

                                {
                                    this.props.field.type === 'tableCollection' &&
                                    <TableCollection
                                        {...this.props}                                        
                                        mainFormData={this.props.formData}
                                        lines={this.props.formData && this.props.formData[this.props.field.id] ? this.props.formData[this.props.field.id] : []}
                                        columns={this.props.field.fields}
                                        oneLine={this.props.field.oneLine}
                                        change={this.props.change}
                                    />
                                }

                                {
                                    this.props.field.type === 'groupBox' &&
                                    <GroupBox
                                        {...this.props}
                                        fields={this.props.field.fields}
                                        options={this.props.field.options ? this.props.field.options : {}}
                                        mainFormData={this.props.formData}
                                        formData={this.props.formData}
                                        change={this.props.change}
                                        
                                    />
                                }
                                {
                                    this.props.field.type === 'additionalModalForm' &&
                                    <ModalForm {...this.props} />
                                } 
                                {
                                    this.props.field.type === 'images' &&
                                    <Images
                                        {...this.props}
                                    />
                                }

                                {
                                    this.props.field.type === 'selectableFields' &&
                                    <FormFields
                                                {...this.props}
                                                label={this.props.field.label}
                                                options={this.props.field.options}
                                                settings={this.props.field.settings}
                                                fields={this.props.field.fields}
                                                change={this.props.change}/>
                                }

                                {
                                    this.props.field.type === 'listSingleCheckboxes' &&
                                    <SingleCheckboxes
                                                {...this.props}
                                                label={this.props.field.label}
                                                options={this.props.field.options}
                                                settings={this.props.field.settings}
                                                fields={this.props.field.fields}
                                                change={this.props.change}/>
                                }
                                
                                {
                                    this.props.field.type === 'button' &&
                                    <CustomButton {...this.props} locale={this.props.locale}/>
                                }

                                {
                                    this.props.field.type === 'dataTable' &&
                                    <DataTable {...this.props} />
                                }
                                
                                {
                                    this.props.field.type === 'commonLink' &&
                                    <CommonLink {...this.props} />
                                }
                                
                                {
                                    this.props.field.type === 'emailMessage' &&
                                    <EmailMessage {...this.props} />
                                }  

                                {
                                    this.props.field.type === 'tabTable' &&
                                    <TabTable {...this.props}/>
                                }
                                
                                {
                                    this.props.field.type === 'radio_buttons' &&
                                    <RadioButtons
                                        {...this.props} 
                                        options={this.props.field.options}/>
                                }
                                
                                {
                                    this.props.field.type === 'selectableArrayFields' &&
                                    <SelectableArrayFields
                                                {...this.props}
                                                label={this.props.field.label}
                                                options={this.props.field.options}
                                                settings={this.props.field.settings}
                                                fields={this.props.field.fields}
                                                change={this.props.change}/>
                                }
                                
                                {
                                    this.props.field.type === 'listAnalisys' &&
                                    <ListAnalisys
                                                {...this.props}
                                                label={this.props.field.label}
                                                options={this.props.field.options}
                                                settings={this.props.field.settings}
                                                fields={this.props.field.fields}
                                                change={this.props.change}/>
                                }
                                
                                {
                                    this.props.field.type === 'customBlocks' &&
                                    <CustomBlocks
                                                {...this.props}
                                                label={this.props.field.label}
                                                change={this.props.change}/>
                                }
                                
                                {
                                    this.props.field.type === 'stages' &&
                                    <StagesList
                                                {...this.props}
                                                label={this.props.field.label}
                                                change={this.props.change}
                                                db={this.props.db}/>
                                }
                                
                                {
                                    this.props.field.type === 'markdown' &&
                                    <Markdown {...this.props} />
                                }

                                {
                                    this.props.field.type === 'color-picker' &&
                                    <ColorPicker {...this.props} />
                                }

                                {
                                    this.props.field.type === 'questions' &&
                                    <SurveyQuestions {...this.props} />
                                }
                                                               
                                {
                                 this.props.meta.visited && this.props.meta.error &&
                                    <div>
                                        <div className="forms-error-msg">
                                            <ErrorMessage
                                                locale={this.props.locale}
                                                error={this.props.meta.error}
                                                message={'errorMessage' in this.props.field ? this.props.field.errorMessage : false}
                                            />
                                            <br/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </React.Fragment>
    }
}

export default Field;
