import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave } from "../../actions/actions";
import { Editor } from '@tinymce/tinymce-react';
import { withRouter } from 'react-router-dom';
import showAToast from '../common/showAToast';
import client from '../../actions/client'

class InlineRteEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.closeField = this.closeField.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : '',
            asHtml: null,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value ) {
            this.setState({
                value:this.props.value ? this.props.value : ''
            });
        }
    }

    saveField(e) {
        let props = this.props;
        let state = this.state;

        let updatedRoute = props.updateRoute;

        window.tinymce.activeEditor.uploadImages().then(function(success) {
            props.onFieldSave(updatedRoute, props.id, window.tinymce.activeEditor.getContent(), props.additionalData).then((response) =>{
                showAToast(plainTranslate(props.settings.locale, 'Data saved successfully!'), 'succSettings', 'success');
                props.handleClose(true);
            });
            
        });
    }

    changeValue(e) {
        this.setState({value: e.target.getContent()});
    }

    closeField(value) {
        this.setState({value: this.props.value ? this.props.value : ''});
        this.props.handleClose();
    }

    imageUpload = (blobInfo, success, failure, progress) => {
        console.log(blobInfo.filename())
        var formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          client
          .post('/' + this.props.match.params.db + '/api/settings/image/upload/tinymce', formData)
          .then(res => {
            success(res.data.location)
          })
          .catch(err => {
            failure('HTTP Error: ' + err.response.code);
          })      
      }

    render() {
        return (
            <div style={{height: 560}}>
                {
                    this.props.isEdit ?
                    <React.Fragment>
                        <Editor apiKey='sl1g8x9zoste0gkigvqhdthmcd9s0j9i5x5piq4jzbece53e'
                            //value={this.state.value}
                            initialValue={this.props.value}
                            init={{
                              plugins: 'autolink link image imagetools code lists advlist media emoticons table preview print searchreplace hr wordcount',
                              file_picker_types: 'file image media',
                              //images_upload_url: '/' + this.props.match.params.db + '/api/task/'+this.props.taskId+'/image/upload/tinymce',
                              images_upload_handler: this.imageUpload,
                              automatic_uploads: false,
                              relative_urls: false,
                              toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist outdent indent | undo redo | image code | media',
                              height : "500",
                              forced_root_block : "", 
                              force_br_newlines : true,
                              force_p_newlines : false
                            }}
                            onChange={this.changeValue}
                        />
                        <button className='button-primary' type="button" onClick={this.saveField}>
                            {plainTranslate(this.props.settings.locale, 'Save')}
                        </button>
                        <button className='button-outline grey' type="button" onClick={this.closeField}>
                            {plainTranslate(this.props.settings.locale, 'Cancel')}
                        </button>
                    </React.Fragment>
                    :
                    <React.Fragment>
                           <div style={{overflow: 'auto', height: '500px', backgroundColor: "#fff"}}><div className="profile-description-rte" dangerouslySetInnerHTML={{__html: this.state.value.toString('html') ? this.state.value.toString('html') : ''}}></div></div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InlineRteEdit));
