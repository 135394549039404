import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import Message from '../common/message';
import { connect } from 'react-redux';
import {Field as FormField, reduxForm} from 'redux-form';
import SaveCancel from '../common/saveCancelButtons';
import Field from "../redux-forms/field";
import FormLoader from "../forms/form-loader";
import Collection from '../redux-forms/types/collection';
import { withRouter } from 'react-router-dom'

class PosPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: true,
            searchUrl: '/' + this.props.match.params.db + '/api/items/find/items',
            selectItemUrl: '/' + this.props.match.params.db + '/api/items/item/item-search',
            barcodeUrl: '/'+this.props.match.params.db+'/api/items/find/barcode/item'
            //quantityUrl: '/' + this.props.match.params.db + '/api/items/item/item-process-by-quantity'
        };
    }

    render() {
        const fields = this.props.formProperties.form && this.props.formProperties.form.fields ? this.props.formProperties.form.fields : [];
        const formData = this.props.formData  ? this.props.formData : [];

        return (<div>
                {!this.props.formProperties.showLoader &&
                    <div id="posPage" className="posPage">
                        <div className="col-md-5 col-xs-12 col-sm-6">
                            <Collection {...this.props} barcodeUrl={this.state.barcodeUrl} searchUrl={this.state.searchUrl} selectItemUrl={this.state.selectItemUrl} categories={this.props,formData.categories}/>
                        </div>
                        <div className="col-md-7 col-xs-12 col-sm-6 pos-customer" style={{ marginTop:13}}>
                            <form onSubmit={this.props.handleSubmit}>
                                {
                                    fields.map((field, key) => {
                                        return <div key={key}>
                                            <FormField
                                                locale={this.props.settings.locale}
                                                name={field.id}
                                                label={field.label}
                                                field={field}
                                                fields={this.props.fields}
                                                history={this.props.history}
                                                component={Field}
                                                change={this.props.change}
                                                type={field.type}
                                                hide={field.hide}
                                                formData={formData}
                                                formProperties={this.props.formProperties}
                                                placeholder={field.label}
                                                db={this.props.match.params.db}
                                            />
                                        </div>
                                    })
                                }
                            </form>
                            {!this.props.notSaveButton &&
                                <div className="col-md-12 col-xs-12">
                                    <div className="pull-right pos-button">
                                        <SaveCancel
                                            enableSave={this.props.enableSave}
                                            save={this.props.save}
                                            printAndSave={this.props.printAndSave}
                                            apply={this.props.apply}
                                            showCancel={this.props.showCancel}
                                            applyButton={this.props.applyButton}
                                            showSaveNew={this.props.showSaveNew}
                                            issueButton = {this.props.issueButton}
                                            cancel={this.props.onCancel}
                                            checkout={this.props.save}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.props.formProperties.showLoader &&
                    <FormLoader/>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
    });

const mapDispatchToProps = dispatch => ({

    });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PosPage));
