import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import {onItemProfileFetchData, onGridExportClick} from '../../../actions/actions';
import FormLoader from '../../forms/form-loader';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import { Table, Input, Button, Tabs, Form, Switch, Pagination, Col, Row } from 'antd';
import { SearchOutlined, ExportOutlined } from '@ant-design/icons'
import 'antd/lib/table/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/icon/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/tabs/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/timeline/style/index.css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/switch/style/index.css';
import 'antd/lib/pagination/style/index.css';
import Popup from "../../common/popup";
import Highlighter from 'react-highlight-words';
import { Timeline } from 'antd';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import moment from 'moment';

const { TabPane } = Tabs;

class ItemProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 'itemAvailability',
            deletePopup: false,
            checked: 1,
            page: 1,
            sorting: false
        };
 
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }

        let params = '?page=1';
        if (this.state.active  == 'itemAvailability') {
            params += '&notNull=1';
        }

        this.setState({active: localStorage.getItem('activeItemTab') ? localStorage.getItem('activeItemTab') : 'itemAvailability'}, function() {
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
        });
        this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/profile-info', 'item');
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {

            this.setState({checked: 1});

            let params = '?page=1';

            if (this.state.active  == 'itemAvailability') {
                params += '&notNull=1';
            }
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/profile-info', 'item');
        }
    }
    
    handleSort(field, sortBy) {
        let sorting = false;
        if (!this.state.field) {
           if (!this.state.sorting) {
                sorting = "ASC";
            }  
        }
        if (field == this.state.field){
            if (!this.state.sorting) {
                sorting = "ASC";
            } 
            if (this.state.sorting == "ASC") {
                sorting = "DESC";
            }
            if (this.state.sorting == "DESC") {
                sorting = null;
                field = null;
            }
        } else {
            sorting = "ASC";
        }
        
        this.setState({
            page: 1,
            sortBy: sortBy,
            sorting: sorting, 
            field: field,
        }, function() {
            let params = '';
            params += '?page=' + this.state.page;
            if (this.state.sortBy) {
                params += '&sortBy=' + this.state.sortBy
            }
            if (this.state.active  == 'itemAvailability') {
                params += '&notNull=' + this.state.checked;
            }
            if (this.state.sorting) {
                params += '&sorting=' + this.state.sorting;
            }
            if (this.state.search) {
                params += '&search=' + this.state.search;
            }
            if (this.state.searchField) {
                params += '&searchField=' + this.state.searchField;
            }
            if (this.state.field) {
                params += '&field=' + this.state.field;
            }

            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
        }); 
    }
    
    changePage(page) {
        let params = '';
        params += '?page=' + page;
        if (this.state.sortBy) {
            params += '&sortBy=' + this.state.sortBy
        }
        if (this.state.active  == 'itemAvailability') {
            params += '&notNull=' + this.state.checked;
        }
        if (this.state.sorting) {
            params += '&sorting=' + this.state.sorting;
        }
        if (this.state.search) {
            params += '&search=' + this.state.search;
        }
        if (this.state.searchField) {
            params += '&searchField=' + this.state.searchField;
        }
        if (this.state.field) {
            params += '&field=' + this.state.field;
        }
        this.setState({page: page}, function(){
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
        }); 
    }

    handleExport(columns) {
        let params = '';
        params += '?page=all';
        if (this.state.sortBy) {
            params += '&sortBy=' + this.state.sortBy
        }
        if (this.state.active  == 'itemAvailability') {
            params += '&notNull=' + this.state.checked;
        }
        if (this.state.sorting) {
            params += '&sorting=' + this.state.sorting;
        }
        if (this.state.search) {
            params += '&search=' + this.state.search;
        }
        if (this.state.searchField) {
            params += '&searchField=' + this.state.searchField;
        }
        if (this.state.field) {
            params += '&field=' + this.state.field;
        }

        let url = '/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params;
        
        client.get(url)
        .then(response => response.data)
        .then(response => {
            var exportData = null;
            if(this.state.active === 'billOfMaterials'){
                exportData = response;
            }else{
                exportData = response.data;
            }
           
            this.setState({exportData : exportData}, function(){
                let visibleColumns = {};
                let i=0;

                columns.map((value) => {
                    console.log(value)
                    if(value.dataIndex !== 'actions'){
                        visibleColumns[i] = [];
                        visibleColumns[i].push(value.dataIndex);
                        visibleColumns[i].push(value.exportTitle);
                        i++;
                    }
                });
               
                let gridData = this.state.exportData;
                let route = "/" + this.props.match.params.db + "/api/grid/export/excel";
                this.props.onGridExportClick(route, gridData, visibleColumns, null);
            }); 
        });
    }

    handleDeleteItem(id) {
        client.post('/' + this.props.match.params.db + '/api/administration/items/delete-list', {'body': id})
        .then(response => response.json())
        .then((response) => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                showAToast(plainTranslate(this.props.settings.locale, 'Success! You have deleted the item!'), 'succSettings', 'success');
                this.props.history.push('/' + this.props.match.params.db + '/administration/items-v2');
            }
        })

        this.setState({
            deletePopup: false,
        });
    }

    changeTab(tab) {
        localStorage.setItem('activeItemTab', tab);
        this.setState({
            active: tab,
            page: 1,
            sorting: null,
            sortBy: null,
            notNull: null,
            search: null,
            field: null,
            dataIndex: null
        }, function() {
            let params = '?page=1';

            if (this.state.active  == 'itemAvailability') {
                params += '&notNull=' + this.state.checked;
            }
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
        });
    }

    handleDataChange (change) {
        if (change === true) {
            change = 1;
        } else {
            change = 0;
        }
        
        this.setState({ checked : change, page : 1 }, function() {
            let params = '';
            params += '?page=1';
            if (this.state.sortBy) {
                params += '&sortBy=' + this.state.sortBy
            }
            if (this.state.active  == 'itemAvailability') {
                params += '&notNull=' + this.state.checked;
            }
            if (this.state.sorting) {
                params += '&sorting=' + this.state.sorting;
            }
            if (this.state.search) {
                params += '&search=' + this.state.search;
            }
            if (this.state.searchField) {
                params += '&searchField=' + this.state.searchField;
            }
            if (this.state.field) {
                params += '&field=' + this.state.field;
            }
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
        });
    };

    render() {

        let itemHistory = this.props.itemProfile && this.props.itemProfile.itemHistory && this.props.itemProfile.itemHistory.data ? this.props.itemProfile.itemHistory.data : [];
        let itemAvailability = this.props.itemProfile && this.props.itemProfile.itemAvailability && this.props.itemProfile.itemAvailability.data ? this.props.itemProfile.itemAvailability.data : [];
        let sales =  this.props.itemProfile && this.props.itemProfile.sales && this.props.itemProfile.sales.data ? this.props.itemProfile.sales.data : [];
        let expenses =  this.props.itemProfile && this.props.itemProfile.expenses && this.props.itemProfile.expenses.data ? this.props.itemProfile.expenses.data : [];
        let associatedPrice =  this.props.itemProfile && this.props.itemProfile.associatedPrice && this.props.itemProfile.associatedPrice.data ? this.props.itemProfile.associatedPrice.data : [];
        let billOfMaterials =  this.props.itemProfile && this.props.itemProfile.billOfMaterials ? this.props.itemProfile.billOfMaterials : [];
        let item = this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.item ? this.props.itemProfile.item.item : [];
        let measures = this.props.itemProfile && this.props.itemProfile.item ? this.props.itemProfile.item.measures : [];
        let image = this.props.itemProfile && this.props.itemProfile.item ? this.props.itemProfile.item.imageUrl : [];
        let orderedItems = this.props.itemProfile && this.props.itemProfile.requestedItems && this.props.itemProfile.requestedItems.data ? this.props.itemProfile.requestedItems.data : [];
        let defaultCurrency = this.props.itemProfile && this.props.itemProfile.item ? this.props.itemProfile.item.defaultCurrency : '';
        let additionalFields = this.props.itemProfile && this.props.itemProfile.item ? this.props.itemProfile.item.additionalFields : null;
        let additionalData = this.props.itemProfile && this.props.itemProfile.item ? this.props.itemProfile.item.additionalData : [];
        let productContent = this.props.itemProfile.item ? this.props.itemProfile.item.productContent : null;
        let contentProducts = this.props.itemProfile && this.props.itemProfile.contentProduct && this.props.itemProfile.contentProduct.data ? this.props.itemProfile.contentProduct.data : [];

        const getColumnSearchProps = (dataIndex, field) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`${plainTranslate(this.props.settings.locale, 'Search')} ${plainTranslate(this.props.settings.locale, dataIndex)}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, field)}
              style={{ width: 188, marginBottom: 8, display: 'block', height: 30 }}
            />
            <div>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex, field)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8}}
            >
              {plainTranslate(this.props.settings.locale, 'Search')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              {plainTranslate(this.props.settings.locale, 'Reset')}
            </Button>
            </div>
          </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : null}
            />
          ) : (
            text
          ),
      });

    const handleSearch = (selectedKeys, confirm, dataIndex, field) => {
        let params = '';
        this.setState({
            page: 1, 
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        }, function() {
            params += '?page=' + this.state.page;
            if (this.state.sortBy) {
                params += '&sortBy=' + this.state.sortBy
            }
            if (this.state.active  == 'itemAvailability') {
                params += '&notNull=' + this.state.checked;
            }
            if (this.state.sorting) {
                params += '&sorting=' + this.state.sorting;
            }
            if (this.state.field) {
                params += '&field=' + this.state.field;
            }
            params += '&search=' + selectedKeys[0];
            params += '&searchField=' + field;
        });

        this.setState({search: selectedKeys[0], searchField: field, dataIndex: dataIndex}, function(){
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
        });
        
      };

    const handleReset = clearFilters => {
        let params = '';
        this.setState({page: 1, search: null, field: null, searchText: ''}, function() {
            params += '?page=' + this.state.page;
            if (this.state.sortBy) {
                params += '&sortBy=' + this.state.sortBy
            }
            if (this.state.active  == 'itemAvailability') {
                params += '&notNull=' + this.state.checked;
            }
            if (this.state.sorting) {
                params += '&sorting=' + this.state.sorting;
            }
        })
        
        this.setState({dataIndex: null}, function(){
            this.props.onItemProfileFetchData('/' + this.props.match.params.db + '/api/item/' + this.props.match.params.id + '/' + this.state.active + params, this.state.active);
        });
      };

      const associatedPriceColumns = [
        {
            'key': 'name',
            'dataIndex' : 'name',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Name'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('name', 'itp.name')}><span className={this.state.active == 'associatedPrice' && this.state.dataIndex == 'name' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Name')}</span> <i className={this.state.active == 'associatedPrice' && this.state.field == 'name' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('name', 'itp.name'),
        },
        {
            'key': 'priority',
            'dataIndex' : 'priority',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Priority'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('priority','itp.priority')}>{plainTranslate(this.props.settings.locale, 'Priority')} <i className={this.state.active == 'associatedPrice' && this.state.field == 'priority' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
        },
        {
            'key': 'type',
            'dataIndex' : 'type',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Type'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('type','itp.type')}><span className={this.state.active == 'associatedPrice' && this.state.dataIndex == 'type' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Type')}</span> <i className={this.state.active == 'associatedPrice' && this.state.field == 'type' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('type', 'itp.type'),
        },
        {
            'key': 'discount',
            'dataIndex' : 'discount',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Discount'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('discount','itp.discountPercent')}><span className={this.state.active == 'associatedPrice' && this.state.dataIndex == 'discount' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Discount')}</span> <i className={this.state.active == 'associatedPrice' && this.state.field == 'discount' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,    
            ...getColumnSearchProps('discount', 'itp.discountPercent'),
        },
        {
            'key': 'price',
            'dataIndex' : 'price',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Price'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('price', 'itp.discountPrice')}><span className={this.state.active == 'associatedPrice' && this.state.dataIndex == 'price' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Price')}</span> <i className={this.state.active == 'associatedPrice' && this.state.field == 'price' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('price', 'itp.discountPrice'),
        },
      ];

    const billOfMaterialsColumns = [
        {
            'key': 'item',
            'dataIndex' : 'item',
            'title' : plainTranslate(this.props.settings.locale, 'Item'),
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Item')
        },
        {
            'key': 'description',
            'dataIndex' : 'description',
            'title' : plainTranslate(this.props.settings.locale, 'Description'),
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Description')

        },
        {
            'key': 'quantity',
            'dataIndex' : 'quantity',
            'title' : plainTranslate(this.props.settings.locale, 'Quantity'),
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Quantity')
        },
        {
            'key': 'price',
            'dataIndex' : 'price',
            'title' : plainTranslate(this.props.settings.locale, 'Price'),
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Price')
        },
        {
            'key': 'total',
            'dataIndex' : 'total',
            'title' : plainTranslate(this.props.settings.locale, 'Total'),
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Total')
        }
      ];

      const salesColumns = [
        {
            'key' : 'date',
            'dataIndex' : 'date',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Date'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('date','s.documentDate')}>{plainTranslate(this.props.settings.locale, 'Date')} <i className={this.state.active == 'sales' && this.state.field == 'date' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,    
            responsive: ['md', 'sm', 'lg'],
            render: (text, record) => {
                return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
            }
        },
        {
            'key' : 'no',
            'dataIndex' : 'no',
            responsive: ['md', 'sm', 'lg'],
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Document No'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('no', 's.documentNo')}>
                <span className={this.state.active == 'sales' && this.state.dataIndex == 'no' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Document No')}</span> <i className={this.state.active == 'sales' && this.state.field == 'no' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}  aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('no', 's.documentNo'),
            render: (text, record) => record.hasEdit
                ? <a target="_blank" href={"/" + this.props.match.params.db + "/sale/" + record.id + "/profile"}>{text}</a>
                : <span> {text} </span>,
        },
        {
            'key' : 'status',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'status',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Status'),
            'title' : plainTranslate(this.props.settings.locale, 'Status'),
        },
        {
            'key' : 'location',
            'dataIndex' : 'location',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Location'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('location','w.locationName')}>
                <span className={this.state.active == 'sales' && this.state.dataIndex == 'location' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Location')}</span> <i className={this.state.active == 'sales' && this.state.field == 'location' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('location', 'w.locationName'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'customer',
            'dataIndex' : 'customer',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Customer'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('customer','b.billingdataCompanyName')}><span className={this.state.active == 'sales' && this.state.dataIndex == 'customer' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Customer')}</span> <i className={this.state.active == 'sales' && this.state.field == 'customer' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('customer', 'b.billingdataCompanyName'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'batch',
            'dataIndex' : 'batch',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Batch'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('batch', 'itb.name')}><span className={this.state.active == 'sales' && this.state.dataIndex == 'batch' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Batch')}</span> <i className={this.state.active == 'sales' && this.state.field == 'batch' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('batch', 'itb.name'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'quantity',
            'dataIndex' : 'quantity',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Quantity'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('quantity', 'sl.quantity')}>{plainTranslate(this.props.settings.locale, 'Quantity')} <i className={this.state.active == 'sales' && this.state.field == 'quantity' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'quantityReserved',
            'dataIndex' : 'quantityReserved',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Reserved Quantity'),
            'title' : plainTranslate(this.props.settings.locale, 'Reserved Quantity'),
            responsive: ['md', 'sm', 'lg'],
        },
        this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showSalePrice ? {
            'key' : 'salePrice',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'salePrice',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Sale Price'),
            'title' : plainTranslate(this.props.settings.locale, 'Sale Price'),
        } : {},
        this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showSalePrice ? {
            'key' : 'total',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'total',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Total'),
            'title' : plainTranslate(this.props.settings.locale, 'Total'),
        } : {},

    ]

    const expenseColumns = [
        {
            'key' : 'date',
            'dataIndex' : 'date',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Date'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('date','ex.date')}>{plainTranslate(this.props.settings.locale, 'Date')} <i className={this.state.active == 'expenses' && this.state.field == 'date' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,    
            responsive: ['md', 'sm', 'lg'],
            render: (text, record) => {
                return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
            }
        },
        {
            'key' : 'no',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'no',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Document No'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('no', 'ex.documentNo')}><span className={this.state.active == 'expenses' && this.state.dataIndex == 'no' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Document No')}</span> <i className={this.state.active == 'expenses' && this.state.field == 'no' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}  aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('no', 'ex.documentNo'),
            render: (text, record) => record.hasEdit && text !== "Without document No"
                ? <a target="_blank" href={"/" + this.props.match.params.db + "/expense/" + record.id + "/profile"}>{text}</a>
                : <span> {text ? text : plainTranslate(this.props.settings.locale, 'Without document No')} </span>,
        },
        {
            'key' : 'status',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'status',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Status'),
            'title' : plainTranslate(this.props.settings.locale, 'Status'),
        },
        {
            'key' : 'location',
            'dataIndex' : 'location',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Location'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('location','w.locationName')}><span className={this.state.active == 'expenses' && this.state.dataIndex == 'location' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Location')}</span> <i className={this.state.active == 'expenses' && this.state.field == 'location' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('location', 'w.locationName'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'vendor',
            'dataIndex' : 'vendor',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Vendor'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('vendor','abd.billingdataCompanyName')}><span className={this.state.active == 'expenses' && this.state.dataIndex == 'vendor' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Vendor')}</span> <i className={this.state.active == 'expenses' && this.state.field == 'vendor' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('vendor', 'abd.billingdataCompanyName'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'quantity',
            'dataIndex' : 'quantity',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Quantity'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('quantity', 'exl.quantity')}>{plainTranslate(this.props.settings.locale, 'Quantity')} <i className={this.state.active == 'expenses' && this.state.field == 'quantity' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'unitPriceOriginal',
            'dataIndex' : 'unitPriceOriginal',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Unit Price Original'),
            'title' : plainTranslate(this.props.settings.locale, 'Unit Price Original'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'currencyOriginal',
            'dataIndex' : 'currencyOriginal',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Currency Original'),
            'title' : plainTranslate(this.props.settings.locale, 'Currency Original'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'unitPrice',
            'dataIndex' : 'unitPrice',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Unit Price'),
            'title' : plainTranslate(this.props.settings.locale, 'Unit Price'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'unitCost',
            'dataIndex' : 'unitCost',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Unit Cost'),
            'title' : plainTranslate(this.props.settings.locale, 'Unit Cost'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key' : 'total',
            'dataIndex' : 'total',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Total'),
            'title' : plainTranslate(this.props.settings.locale, 'Total'),
            responsive: ['md', 'sm', 'lg'],
        }

    ]

    const availabilityColumns = [
        {
            'key': 'batch',
            'dataIndex' : 'batch',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Batch'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('batch', 'itb.name')}><span className={this.state.active == 'itemAvailability' && this.state.dataIndex == 'batch' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Batch')}</span> <i className={this.state.active == 'itemAvailability' && this.state.field == 'batch' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('batch', 'itb.name'),
        },
        {
            'key': 'location',
            'dataIndex' : 'location',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Location'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('location', 'l.locationName')}><span className={this.state.active == 'itemAvailability' && this.state.dataIndex == 'location' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Location')}</span> <i className={this.state.active == 'itemAvailability' && this.state.field == 'location' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('location', 'l.locationName'),
        },
        {
            'key': 'quantity',
            'dataIndex' : 'quantity',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Quantity'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('quantity', 'qtyOnHand')}>{plainTranslate(this.props.settings.locale, 'Quantity')} <i className={this.state.active == 'itemAvailability' && this.state.field == 'quantity' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
        },
        {
            'key': 'reservedQty',
            'dataIndex' : 'reservedQty',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Reserved Quantity'),
            'title' : plainTranslate(this.props.settings.locale, 'Reserved Quantity'),
        },
        {
            'key': 'moveQty',
            'dataIndex' : 'moveQty',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Quantity in transit'),
            'title' : plainTranslate(this.props.settings.locale, 'Quantity in transit'),
        },
        {
            'key': 'availableQty',
            'dataIndex' : 'availableQty',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Available Quantity'),
            'title' : plainTranslate(this.props.settings.locale, 'Available Quantity'),
        },
        this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showPurchasePrice ? {
            'key': 'value',
            'dataIndex' : 'value',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Purchase Price'),
            'title' : plainTranslate(this.props.settings.locale, 'Purchase Price'),
        } : {},
        this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showPurchasePrice ? {
            'key': 'total',
            'dataIndex' : 'total',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Total'),
            'title' : plainTranslate(this.props.settings.locale, 'Total'),
        } : {},
    ];

    const columns = [
        {
            'key': 'actions',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'actions',
            'title' : plainTranslate(this.props.settings.locale, 'Actions'),
            render: (text, record) => (
                <span>
                {record.hasEdit &&
                    <Link to={"/" + this.props.match.params.db + "/receipt/" + record.id + "/edit"} title={plainTranslate(this.props.settings.locale, 'Edit')}><i className="fa fa-pencil-square-o"></i></Link>
                }
                    {this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showSalePrice && this.props.itemProfile.item.showPurchasePrice &&
                    <a href={"/" + this.props.match.params.db + "/inventories/receipts/" + record.id + "/export.pdf"} target="blank" title={plainTranslate(this.props.settings.locale, 'Export')}><i className="fa fa-print m-left15"></i></a>
                    }
                </span>
            ),
        },
        {
            'key': 'date',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'date',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Date'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('date', 'a.receiptDate')}>{plainTranslate(this.props.settings.locale, 'Date')} <i className={this.state.active == 'itemHistory' && this.state.field == 'date' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,   
            render: (text, record) => {
                return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
            } 
        },
        {
            'key': 'type',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'type',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Type'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('type', 'f.name')}><span className={this.state.active == 'itemHistory' && this.state.dataIndex == 'type' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Type')}</span> <i className={this.state.active == 'itemHistory' && this.state.field == 'type' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"} aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('type', 'f.name'),
        },
        {
            'key': 'no',
            'dataIndex' : 'no',
            responsive: ['md', 'sm', 'lg'],
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Document No'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('no', 'a.receiptDocumentNo')}><span className={this.state.active == 'itemHistory' && this.state.dataIndex == 'no' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Document No')}</span> <i className={this.state.active == 'itemHistory' && this.state.field == 'no' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}  aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('no', 'a.receiptDocumentNo'),
            render: (text, record) => record.hasEdit
                ?  <a target="_blank" href={"/" + this.props.match.params.db + "/receipt/" + record.id + "/profile"}>{text}</a>
                : <span> {text} </span>,
        },
        {
            'key': 'stage',
            'dataIndex' : 'stage',
            responsive: ['md', 'sm', 'lg'],
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Stage'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('stage', 'st.name')}><span className={this.state.active == 'itemHistory' && this.state.dataIndex == 'stage' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Stage')}</span> <i className={this.state.active == 'itemHistory' && this.state.field == 'stage' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('stage', 'st.name'),
        },
        {
            'key': 'company',
            'dataIndex' : 'company',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Partner'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('company', 'b.billingdataCompanyName')}><span className={this.state.active == 'itemHistory' && this.state.dataIndex == 'company' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Partner')}</span> <i className={this.state.active == 'itemHistory' && this.state.field == 'company' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('company', 'b.billingdataCompanyName'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key': 'location',
            'dataIndex' : 'location',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Location'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('location', 'w.locationName')}><span className={this.state.active == 'itemHistory' && this.state.dataIndex == 'location' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Location')}</span> <i className={this.state.active == 'itemHistory' && this.state.field == 'location' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('location', 'w.locationName'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key': 'batch',
            'dataIndex' : 'batch',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Batch'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('batch', 's.name')}><span className={this.state.active == 'itemHistory' && this.state.dataIndex == 'batch' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Batch')}</span> <i className={this.state.active == 'itemHistory' && this.state.field == 'batch' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('batch', 's.name'),
            responsive: ['md', 'sm', 'lg'],
        },
        {
            'key': 'quantity',
            'dataIndex' : 'quantity',
            responsive: ['md', 'sm', 'lg'],
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Quantity'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('quantity', 'l.receiptLineItemQuantity')}>{plainTranslate(this.props.settings.locale, 'Quantity')} <i className={this.state.active == 'itemHistory' && this.state.field == 'quantity' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
        },
        this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showSalePrice && this.props.itemProfile.item.showPurchasePrice ? {
            'key': 'price',
            'dataIndex' : 'price',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Price'),
            'title' : plainTranslate(this.props.settings.locale, 'Price'),
        } : {},
        this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showSalePrice && this.props.itemProfile.item.showPurchasePrice ? {
            'key': 'baseAmount',
            'dataIndex' : 'baseAmount',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Amount'),
            'title' : plainTranslate(this.props.settings.locale, 'Amount'),
        } : {},
    ];

    const itemRequestColumns = [
        {
            'key': 'requestNo',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'requestNo',
            'exportTitle' : plainTranslate(this.props.settings.locale, '#'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('requestNo', 'i.requestNo')}><span className={this.state.active == 'requestedItems' && this.state.dataIndex == 'requestNo' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, '#')}</span> <i className={this.state.active == 'requestedItems' && this.state.field == 'requestNo' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('requestNo', 'i.requestNo'),
        },
        {
            'key': 'requestDate',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'requestDate',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Date'),
            render: (text, record) => {
                return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
            },
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('requestDate', 'i.requestDate')}>{plainTranslate(this.props.settings.locale, 'Date')} <i className={this.state.active == 'requestedItems' && this.state.field == 'requestDate' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
        },   
        {
            'key': 'status',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'status',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Status'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('status', 'st.status')}>{plainTranslate(this.props.settings.locale, 'Status')} <i className={this.state.active == 'requestedItems' && this.state.field == 'status' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
        },
        {
            'key': 'locationFrom',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'locationFrom',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Location from'),
            'title' :  <span className="cursorSelect" onClick = {() => this.handleSort('locationFrom', 'lf.locationName')}><span className={this.state.active == 'requestedItems' && this.state.dataIndex == 'locationFrom' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Location from')}</span> <i className={this.state.active == 'requestedItems' && this.state.field == 'locationFrom' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('locationFrom', 'lf.locationName'),
        },
        {
            'key': 'locationTo',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'locationTo',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Location to'),
            'title' : <span className="cursorSelect" onClick = {() => this.handleSort('locationTo', 'lt.locationName')}><span className={this.state.active == 'requestedItems' && this.state.dataIndex == 'locationTo' && this.state.search ? "text-italic" : ""}>{plainTranslate(this.props.settings.locale, 'Location to')}</span> <i className={this.state.active == 'requestedItems' && this.state.field == 'locationTo' && this.state.sorting ? "fa fa-sort text-red" : "fa fa-sort"}   aria-hidden="true" ></i></span>,  
            ...getColumnSearchProps('locationTo', 'lt.locationName'),
        },
        {
            'key': 'quantity',
            responsive: ['md', 'sm', 'lg'],
            'dataIndex' : 'quantity',
            'exportTitle' : plainTranslate(this.props.settings.locale, 'Quantity'),
            'className': 'column-money',
            'title' : plainTranslate(this.props.settings.locale, 'Quantity'),
            render: (text, record) => (
                <span>
                    <p className='text-red text-right'>{record.quantity}</p>
                </span>
              ),
        },
    ];
    
    const expandedRow = row => {
        let subTable = row.subRequest;
        let color = 'yellow';
        if (subTable.length > 0) {
        return <Timeline mode={'left'}>
                {subTable.map((record, key) => {
                if (record.stage == "Actual") {
                    color = 'green';
                } else if (record.stage == "Draft") {
                    color = 'blue';
                } else if (record.stage == "Closed") {
                    color = 'red';
                } 
                   return <React.Fragment>
                            <Timeline.Item color={color} label={record.locationFrom}>
                                    <p>{plainTranslate(this.props.settings.locale, 'From')} <strong>{record.locationFrom}</strong> {plainTranslate(this.props.settings.locale, 'To')} <strong>{record.locationTo}</strong> | {record.requestDate}</p>
                                    <p>{plainTranslate(this.props.settings.locale, 'Quantity:')} {record.quantity}</p>
                                    <p>{plainTranslate(this.props.settings.locale, 'Status:')} {record.status}</p>
                                    {record.transfer &&
                                        <p>{plainTranslate(this.props.settings.locale, 'Transfer:')} {record.transfer}</p>
                                    }
                                    {record.purchase &&
                                        <p>{plainTranslate(this.props.settings.locale, 'Purchase:')} {record.purchase}</p>
                                    }
                            </Timeline.Item>
                    </React.Fragment>
                    }
                )} 
            </Timeline>
        } else {
            return <p>{plainTranslate(this.props.settings.locale, 'No data')}</p>
        }
      }
      
        return (
                <div className="item-profile">
                   {this.props.itemProfile.itemLoading ? <FormLoader /> : <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                        <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                            <Link to={"/" + this.props.match.params.db + "/administration/items-v2"}>
                                <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Items')}
                            </Link>
                            {this.props.gridPopup && <a style={{marginRight: 20, float:"right"}} href={"/" + this.props.match.params.db + '/item/'+ this.props.match.params.id +'/profile'} target="_blank">{plainTranslate(this.props.settings.locale, 'Open in new tab')}<ExportOutlined style={{fontSize: 15, marginLeft:5}}/></a>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                        <div className="general item-profile-left col-md-12 col-xs-24 no-gutter">
                            {this.props.itemProfile.itemLoading  ? <FormLoader /> : <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 account-profile-information">
                                {item.deleted !== null &&
                                    <div className="col-lg-8 col-md-8 col-xs-8 no-gutter">
                                        <h5 className="col-md-12 col-xs-12 infoMessage text-red"><i className="fa fa-ban" aria-hidden="true"></i> {plainTranslate(this.props.settings.locale, "Item is deleted!")}</h5>
                                    </div>
                                }
                             <div className="col-lg-8 col-md-8 col-xs-8 no-gutter">
                                <h1 className="item-title">{item ? item.title : ''}</h1>
                              </div>
                              <div className="col-lg-2 col-md-2 col-xs-2 no-gutter item-picture">
                                <img src={image ? image : '/images/no-image.png'}/>
                              </div>
                                {this.props.itemProfile && this.props.itemProfile.item && (this.props.itemProfile.item.hasEdit || this.props.itemProfile.item.hasDelete) &&
                                    <div className="col-lg-2 col-md-2 col-xs-1" id="action-menu" style={{    marginTop: 20}}>
                                    <i className="ion-android-more-vertical" title={plainTranslate(this.props.settings.locale, "More actions")}></i>
                                        <div className="account-action">
                                            <ul>
                                                {this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.hasEdit &&
                                                    <li>
                                                        <Link to={"/" + this.props.match.params.db + "/administration/items/" + item.id + "/edit"}>
                                                            {plainTranslate(this.props.settings.locale, 'Edit')}
                                                        </Link>
                                                    </li>
                                                }
                                                {this.props.settings.plan.content && productContent &&
                                                    <li>
                                                        <Link to={productContent !== 'products' ? "/" + this.props.match.params.db + "/content-data/" + productContent + "/new?item=" + item.id :  "/" + this.props.match.params.db + "/products/new?item=" + item.id}>
                                                            {plainTranslate(this.props.settings.locale, 'Create product')}
                                                        </Link>
                                                    </li>
                                                }
                                                {this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.hasDelete &&
                                                    <li className="separate"></li>
                                                }
                                                {this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.hasDelete &&
                                                    
                                                    <li>
                                                        <a href="javascript:void(0)" className="text-red" onClick={() => this.setState({deletePopup: true})}
                                                           data-target="#deleteModal"
                                                           data-toggle='modal'>{plainTranslate(this.props.settings.locale, 'Delete')}</a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }

                                <div className="col-lg-12 col-md-12 col-xs-12 no-gutter" style={{display:"flex"}}>
                                    <p style={{marginRight:10}}>{item ? item.no : ''}</p>
                                    <p style={{marginRight:10}}>{item && item.status ? '✓ ' + plainTranslate(this.props.settings.locale, 'active') : '✗ ' + plainTranslate(this.props.settings.locale, 'disable')}</p>
                                </div>
                                {item && item.price != '' && this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showSalePrice &&
                                <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                    <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                        <span>{plainTranslate(this.props.settings.locale, 'Sale Price')}:</span>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-xs-8 content">
                                        <span>{item ? item.price : ''}</span>
                                    </div>
                                </div>
                               }
                                {item && item.currencyName != '' &&
                                <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                    <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                        <span>{plainTranslate(this.props.settings.locale, 'Currency')}: </span>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-xs-8 content">
                                        <span>{item ? item.currencyName : ''}</span>
                                    </div>
                                </div>
                                }
                                {item && item.measure != '' &&
                                <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                    <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                        <span>{plainTranslate(this.props.settings.locale, 'Measure')}:</span>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                        <span>{item ? item.measure : ''}</span>
                                    </div>
                                </div>
                               }
                               {item && item.weight != '' &&
                               <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                   <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                       <span>{plainTranslate(this.props.settings.locale, 'Weight')}:</span>
                                   </div>
                                   <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                       <span>{item ? item.weight : ''}</span>
                                   </div>
                               </div>
                              }
                                {item && item.shortDescription != '' &&
                                <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                    <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                        <span>{plainTranslate(this.props.settings.locale, 'Short description')}:</span>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                        <span>{item ? item.shortDescription : ''}</span>
                                    </div>
                                </div>
                               }
                               {item && item.barcode != '' &&
                               <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                   <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                       <span>{plainTranslate(this.props.settings.locale, 'Barcode')}:</span>
                                   </div>
                                   <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                       <span>{item.barcode}</span>
                                   </div>
                               </div>
                              }
                               {item && item.categoryName != '' &&
                               <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                   <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                       <span>{plainTranslate(this.props.settings.locale, 'Category')}:</span>
                                   </div>
                                   <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                       <span>{item ? item.categoryName : ''}</span>
                                   </div>
                               </div>
                              }
                            {item && item.countStock != '' &&
                               <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                   <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                       <span>{plainTranslate(this.props.settings.locale, 'Type')}:</span>
                                   </div>
                                   <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                       <span>{item ? item.countStock : ''}</span>
                                   </div>
                               </div>
                              }
                              {item && item.longDescription &&
                               <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                   <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                       <span>{plainTranslate(this.props.settings.locale, 'Description')}:</span>
                                   </div>
                                   <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                       <span dangerouslySetInnerHTML={{ __html: item.longDescription }}></span>
                                   </div>
                               </div>
                              }
                              {additionalFields && additionalFields.fields && additionalFields.fields.length && additionalFields.fields.map((field, key) => {
                                    return <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter" key={key}>
                                                <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                                    <span>{plainTranslate(this.props.settings.locale, field.label)}:</span>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                                    <span>{additionalData.find(val => val.hasOwnProperty(field.id)) ? additionalData.find(val => val.hasOwnProperty(field.id))[field.id] : null}</span>
                                                </div>
                                            </div>
                              })}
                                <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter"/>
                                <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                    <div className="account-profile-other-information-more">
                                        <h3 style={{marginTop:0, marginBottom: 15}}>{plainTranslate(this.props.settings.locale, 'Purchase information')}</h3>
                                    </div>
                                   {item && (item.currentValue != '' && item.currentValue !== null) && this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showPurchasePrice &&
                                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                    
                                        <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                            <span>{plainTranslate(this.props.settings.locale, 'Purchase Price')} <span className="help" title={plainTranslate(this.props.settings.locale, 'The system calculates an average purchase price based on all Inventory documents except Release receipts.')} ><i className="fa fa-info-circle"></i></span> :</span>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                            <span>{item ? item.currentValue: ''}</span> {item && item.currentValue !== null && <span>{defaultCurrency}</span>}
                                        </div>
                                    </div>
                                  }
                                    {item && item.currentValue != '' && this.props.itemProfile && this.props.itemProfile.item && this.props.itemProfile.item.showPurchasePrice &&
                                        <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                           <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                               <span>{plainTranslate(this.props.settings.locale, 'Last Purchase Price')}:</span>
                                           </div>
                                           <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                               <span>{item.purchasePrice}</span>  {item.purchasePrice !== null && <span>{defaultCurrency}</span>}
                                           </div>
                                       </div>
                                    }
                                  {item.supplier != '' &&
                                   <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                   <div className="col-lg-4 col-md-4 col-xs-4 no-gutter-r title">
                                       <span>{plainTranslate(this.props.settings.locale, 'Supplier')}:</span>
                                   </div>
                                   <div className="col-lg-8 col-md-8 col-xs-8 no-gutter-r content">
                                       <span>{item ? item.supplier : ''}</span>
                                   </div>
                               </div>
                                 }
                                </div>

                                {
                                    measures && measures.length > 0 &&
                                    <div>
                                        <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter"/> 
                                        <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                            <div className="account-profile-other-information-more">
                                                <h3 style={{marginTop:0, marginBottom: 15}}>{plainTranslate(this.props.settings.locale, 'Pack information')}</h3>
                                            </div>                                  
                                            <div className="line">
                                                <table  className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Pack')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Conversion')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Pack price')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Unit price')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { measures.map( measure =>
                                                                <tr>
                                                                    <td>{measure.pack}</td>
                                                                    <td>{measure.conversion}</td>
                                                                    <td>{measure.packPrice}</td>
                                                                    <td>{measure.unitPrice}</td>
                                                                </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                }                                
                            </div>
                            }
                          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 no-gutter-m no-gutter-r item-profile-right">
                          <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                                <div className="nav nav-tabs" id="tabBtn">
                                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 basic no-gutter">
                                           {this.props.settings.bundles.inventorybundle && this.props.settings.plan.inventory &&
                                                <button className={this.state.active == 'itemHistory' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('itemHistory')}>
                                                  {plainTranslate(this.props.settings.locale, 'History')}
                                                </button>
                                            }
                                          <button className={this.state.active == 'itemAvailability' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('itemAvailability')}>
                                              {plainTranslate(this.props.settings.locale, 'Availability')}
                                          </button>
                                            {this.props.settings.bundles.salesbundle && this.props.settings.plan.sales &&
                                              <button className={this.state.active == 'sales' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('sales')}>
                                                  {plainTranslate(this.props.settings.locale, 'Sales')}
                                              </button>
                                            }
                                            {this.props.settings.bundles.expensebundle && this.props.settings.plan.expense &&
                                              <button className={this.state.active == 'expenses' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('expenses')}>
                                                  {plainTranslate(this.props.settings.locale, 'Expenses')}
                                              </button>
                                            }
                                          <button className={this.state.active == 'associatedPrice' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('associatedPrice')}>
                                              {plainTranslate(this.props.settings.locale, 'Associated Price Lists')}
                                          </button>
                                          <button className={this.state.active == 'requestedItems' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('requestedItems')}>
                                              {plainTranslate(this.props.settings.locale, 'Requested items')}
                                          </button>
                                          {this.props.settings.plan.content && productContent &&
                                                <button className={this.state.active == 'contentProduct' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('contentProduct')}>
                                                    {plainTranslate(this.props.settings.locale, 'Products')}
                                                </button>
                                          }
                                            {this.props.settings.bundles.productionbundle && this.props.settings.plan.production &&
                                                <button className={this.state.active == 'billOfMaterials' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('billOfMaterials')}>
                                                    {plainTranslate(this.props.settings.locale, 'Bill Of Materials')}
                                                </button>
                                            }
                                      </div>
                                      <div className="col-lg-3 col-md-3 col-xs-12 actions">
                                      {this.state.active === 'itemHistory' && itemHistory.length > 0 &&
                                        <span onClick={() => this.handleExport(columns)} className="pull-right" style={{marginLeft: 6, color: "#4eb0e2", textDecoration: "none",textTransform: "uppercase", cursor: "pointer"}}>
                                            {plainTranslate(this.props.settings.locale, 'Export')} <i className="fa fa-download" aria-hidden="true"></i>
                                        </span>
                                      }
                                      {this.state.active === 'itemAvailability' && itemAvailability.length > 0 &&
                                        <span onClick={() => this.handleExport(availabilityColumns)} className="pull-right" style={{marginLeft: 6, color: "#4eb0e2", textDecoration: "none",textTransform: "uppercase", cursor: "pointer"}}>
                                            {plainTranslate(this.props.settings.locale, 'Export')} <i className="fa fa-download" aria-hidden="true"></i>
                                        </span>
                                      }
                                      {this.state.active === 'sales' && sales.length > 0 &&
                                        <span onClick={() => this.handleExport(salesColumns)} className="pull-right" style={{marginLeft: 6, color: "#4eb0e2", textDecoration: "none",textTransform: "uppercase", cursor: "pointer"}}>
                                          {plainTranslate(this.props.settings.locale, 'Export')} <i className="fa fa-download" aria-hidden="true"></i>
                                        </span>
                                      }
                                      {this.state.active === 'expenses' && expenses.length > 0 &&
                                        <span onClick={() => this.handleExport(expenseColumns )} className="pull-right" style={{marginLeft: 6, color: "#4eb0e2", textDecoration: "none",textTransform: "uppercase", cursor: "pointer"}}>
                                          {plainTranslate(this.props.settings.locale, 'Export')} <i className="fa fa-download" aria-hidden="true"></i>
                                        </span>
                                      }
                                      {this.state.active === 'associatedPrice' && associatedPrice.length > 0 &&
                                        <span onClick={() => this.handleExport(associatedPriceColumns)} className="pull-right" style={{marginLeft: 6, color: "#4eb0e2", textDecoration: "none",textTransform: "uppercase", cursor: "pointer"}}>
                                          {plainTranslate(this.props.settings.locale, 'Export')} <i className="fa fa-download" aria-hidden="true"></i>
                                        </span>
                                      }
                                      {this.state.active === 'requestedItems' && orderedItems.length > 0 &&
                                        <span onClick={() => this.handleExport(itemRequestColumns)} className="pull-right" style={{marginLeft: 6, color: "#4eb0e2", textDecoration: "none",textTransform: "uppercase", cursor: "pointer"}}>
                                          {plainTranslate(this.props.settings.locale, 'Export')} <i className="fa fa-download" aria-hidden="true"></i>
                                        </span>
                                      }
                                    {this.state.active === 'billOfMaterials' &&
                                        <span onClick={() => this.handleExport(billOfMaterialsColumns, billOfMaterials)} className="pull-right" style={{marginLeft: 6, color: "#4eb0e2", textDecoration: "none",textTransform: "uppercase", cursor: "pointer"}}>
                                          {plainTranslate(this.props.settings.locale, 'Export')} <i className="fa fa-download" aria-hidden="true"></i>
                                        </span>
                                      }
                                      </div>
                                </div>
                                {this.props.itemProfile.loading  ? <FormLoader /> : <div className="col-md-12 col-xs-12 no-gutter">
                                    {
                                    this.state.active === 'itemHistory' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table 
                                            scroll={{"x": "80vw"}}
                                            rowKey={record => record.lineNo} 
                                            columns={columns} 
                                            dataSource={this.props.itemProfile && this.props.itemProfile.itemHistory ? this.props.itemProfile.itemHistory.data : []} 
                                            pagination={false}
                                            size="small"/>
                                            {this.props.itemProfile && this.props.itemProfile.itemHistory && this.props.itemProfile.itemHistory.data && this.props.itemProfile.itemHistory.data.length > 0 &&
                                                <Pagination showSizeChanger={false} defaultCurrent={this.state.page} total={this.props.itemProfile.itemHistory.total}  onChange={this.changePage}/>
                                            }
                                        </div>
                                    }
                                    {
                                    this.state.active === 'itemAvailability' &&
                                        <React.Fragment> 
                                            <div className="no-gutter itemHistory-table">
                                             <Row justify="end">
                                                 <Col>
                                                <Form
                                                layout="inline m-top10"
                                                className="components-table-demo-control-bar"
                                                style={{ height: 50 }}
                                                >
                                                    <Form.Item label={plainTranslate(this.props.settings.locale, "Without null")}>
                                                        <Switch checked={this.state.checked} onChange={(change) => this.handleDataChange(change)} />
                                                    </Form.Item>
                                                </Form>
                                                </Col>
                                                </Row>
                                                <Row >
                                                 <Col span={24}>
                                                <Table 
                                                 scroll={{"x": "50vw"}}
                                                    rowKey={record => record.lineNo} 
                                                    columns={availabilityColumns} 
                                                    pagination={false}
                                                    dataSource={itemAvailability} 
                                                    size="small"
                                                />
                                                 </Col>
                                                </Row>
                                                {this.props.itemProfile && this.props.itemProfile.itemAvailability && this.props.itemProfile.itemAvailability.data && this.props.itemProfile.itemAvailability.data.length > 0 &&
                                                    <Pagination showSizeChanger={false} defaultCurrent={this.state.page} total={this.props.itemProfile.itemAvailability.total}  onChange={this.changePage}/>
                                                }
                                            </div>
                                        </React.Fragment>
                                        
                                    }
                                    {this.state.active === 'sales' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table 
                                             scroll={{"x": "80vw"}}
                                            size="small"
                                            rowKey={record => record.lineNo} 
                                            columns={salesColumns} 
                                            dataSource={this.props.itemProfile && this.props.itemProfile.sales ? this.props.itemProfile.sales.data : []} pagination={false}/>
                                            {this.props.itemProfile && this.props.itemProfile.sales && this.props.itemProfile.sales.data && this.props.itemProfile.sales.data.length > 0 &&
                                                <Pagination 
                                                defaultCurrent={this.state.page} 
                                                total={this.props.itemProfile.sales.total}  
                                                onChange={this.changePage} 
                                                showSizeChanger={false}/>
                                            }
                                        </div>
                                    }
                                    {this.state.active === 'expenses' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table 
                                             scroll={{"x": "80vw"}}
                                            size="small" 
                                            rowKey={record => record.lineNo} 
                                            columns={expenseColumns} 
                                            dataSource={this.props.itemProfile && this.props.itemProfile.expenses ? this.props.itemProfile.expenses.data : []} 
                                            pagination={false}/>
                                            {this.props.itemProfile && this.props.itemProfile.expenses && this.props.itemProfile.expenses.data && this.props.itemProfile.expenses.data.length > 0 &&
                                                <Pagination 
                                                showSizeChanger={false} 
                                                defaultCurrent={this.state.page} 
                                                total={this.props.itemProfile.expenses.total} 
                                                defaultPageSize={10} 
                                                onChange={this.changePage}/>
                                            }
                                        </div>
                                    }
                                    {
                                    this.state.active === 'associatedPrice' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table 
                                             scroll={{"x": "50vw"}}
                                            size="small" 
                                            rowKey={record => record.lineNo} 
                                            columns={associatedPriceColumns} 
                                            dataSource={associatedPrice} 
                                            pagination={false}/>
                                            {associatedPrice && associatedPrice.length > 0 &&
                                                <Pagination showSizeChanger={false} defaultCurrent={this.state.page} total={this.props.itemProfile.associatedPrice.total}  onChange={this.changePage}/>
                                            }
                                        </div>
                                    }
                                    {this.state.active === 'requestedItems' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table
                                             scroll={{"x": "50vw"}}
                                                rowKey={record => record.id}
                                                columns={itemRequestColumns}
                                                dataSource={orderedItems}
                                                pagination={false}
                                                expandedRowRender={expandedRow}
                                                size="small"
                                            />
                                            {orderedItems && orderedItems.length > 0 &&
                                                <Pagination showSizeChanger={false} defaultCurrent={this.state.page} total={this.props.itemProfile.requestedItems.total}  onChange={this.changePage}/>
                                            }
                                        </div>
                                    }
                                    {this.props.settings.plan.content && productContent && this.state.active === 'contentProduct' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                          <div className="ant-list ant-list-lg ant-list-bordered">
                                            <div className="ant-spin-container">
                                                <ul className="ant-list-items">
                                                    {contentProducts && contentProducts.length && contentProducts.map((item, key) => {
                                                        return <li className="ant-list-item"><Link key={key} to={"/" + this.props.match.params.db + "/content-data/" + productContent + item.id + "/edit"} title={plainTranslate(this.props.settings.locale, item.name)}>{item.name}</Link></li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                          </div>
                                        </div>
                                    }
                                    {
                                        this.state.active === 'billOfMaterials' &&
                                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                                <Table 
                                                 scroll={{"x": "50vw"}}
                                                size="small" 
                                                rowKey={record => record.lineNo} 
                                                columns={billOfMaterialsColumns} 
                                                dataSource={billOfMaterials} />
                                            </div>
                                    }
                                </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                   </div>
                   }
                   { this.state.deletePopup &&
                        <Popup locale={this.props.settings.locale}
                               title={plainTranslate(this.props.settings.locale, 'Delete item')}
                               onClose={() => this.setState({deletePopup: false})}>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <div className="text-center">
                                            <h3>
                                                <Translate locale={this.props.settings.locale} value="Are you sure you want to delete this item?"/>
                                            </h3>
                                            <button className="button-outlines green text-center"
                                                    onClick={() => this.setState({deletePopup: false})}>
                                                <Translate locale={this.props.settings.locale} value="No"/>
                                            </button>
                                            <button className="button-primary-edited text-center btn-ok"
                                                    onClick={() => this.handleDeleteItem(item.id)}>
                                                <Translate locale={this.props.settings.locale} value="Yes"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Popup>
                    }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    itemProfile: state.itemProfile
});
const mapDispatchToProps = dispatch => ({
    onItemProfileFetchData: (url, type) => dispatch(onItemProfileFetchData(url, type)),
    onGridExportClick: (route, data, cols) => dispatch(onGridExportClick(route, data, cols)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemProfile);
