import React from 'react';
import {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import SlideModal from '../../../common/SlideModal';
import {ACCOUNT_ADD_NEW_PROJECT, ACCOUNT_ADD_NEW_TASK} from "../../../../constants/actionTypes";
import { onTabsLoad} from '../../../../actions/actions';
import { Table, Dropdown, Menu, Button, Progress,Select } from 'antd';
import FormLoader from '../../../forms/form-loader';
import {Link} from 'react-router-dom';
import Form from "../../../redux-forms/form";
import Popup from '../../../common/popup';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import AsyncSelect from 'react-select/lib/Async';
import {PlusOutlined} from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;

const customStyles = {
    input: styles => ({ ...styles, width: 60 })
  }

class Projects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenProject: false,
            editProject: false,
            isOpenTask: false,
            taskForm: false,
            taskProject: false,
            userOptions: [],
            userValues: {},
            userOptionsProject: [],
            userValuesProject: {},
        }    
        this.updateTasks = this.updateTasks.bind(this);
    }

    componentDidMount(){
        this.setState({
            userOptions: this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.users : [],
            userOptionsProject: this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.users : []
        })
    }
    
    updateTasks() {
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
    }

    handleChangeField(e, task, value, type) {
        if(e){
            e.preventDefault();
        }     
        if(type === 'user'){
        let userValues= this.state.userValues;
        userValues[task.id] = value.id;
         this.setState({
            userValues: userValues
         })
         value = value.id;
        }  

        if(type === 'userProject'){
            let userValuesProject= this.state.userValuesProject;
            userValuesProject[task.id] = value.id;
             this.setState({
                userValuesProject: userValuesProject
             })
             value = value.id;
             type = 'user'
            }  

        client
        .post('/' + this.props.match.params.db + '/api/task/' + task.id + '/update-field', {
            id: type,
            value: value
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                this.updateTasks();
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
            }
        });
    }

    onSaveTask(response){
        this.updateTasks();
        this.setState({addNewProject: false, isOpenTask: false, openCopyTask: false, editProject: false, editTask: false, editTaskId: null, parentTask: null});
    }

    copyTask(id) {
        this.setState({openCopyTask: true, copyTaskId: id});
    }

    taskLogTime(id) {
        this.setState({taskLogTime: true, taskId: id});
    }

    createNewTask(e,form, project, parent){
         e.preventDefault();
        this.setState({isOpenTask: true, taskForm: form, taskProject : project,parentTask: parent});
    }

   

    render() {
        const style = {
            margin: '0 5px',
        };

        let taskForms = this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.taskForms : [];
        let projectForms = this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.projectForms : [];
        let projects = this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.projects : [];
        let tasks = this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.tasks : [];
        let taskStages = this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.taskStages : [];
        let users = this.props.projectsAndTasksTable ? this.props.projectsAndTasksTable.users : [];

        const tasksCols = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const FormOptions = (
                        <Menu>
                            {taskForms.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.createNewTask(e,option.form, null, record.id)}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );
                    return (
                        !record.type ? <span className="table-actions">
                            <Link to={'/' + this.props.match.params.db + '/task/' + record.id + '/profile'} title={plainTranslate(this.props.settings.locale, 'Profile')}><i style={style} className="fa fa-eye"></i></Link>
                            <i style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.setState({editTask: true, editTaskId: record.id})} className="fa fa-pencil-square-o"></i>
                            <i style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.copyTask(record.id)} className="fa fa-clone"></i>
                            <i style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.taskLogTime(record.id)} className="ion-android-time"></i>
                            <Dropdown overlay={FormOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={{margin: '0 5px',display: record.parent ? 'none' : 'inline-block'}} className="fa fa-plus-circle"></i>
                                </a>
                            </Dropdown>
                    
                        </span> : <span></span>
                    )
                },
                width: 200,
            },
            {
                'dataIndex' : 'name',
                'title' : plainTranslate(this.props.settings.locale, 'Name'),
                'width': 100,
                'ellipsis': true,
                render: (text, record) => {
                    return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showTaskHint: true, taskHint: record})}>{record.name}</span>)
                }
            },
            {
                'dataIndex' : 'status',
                'title' : plainTranslate(this.props.settings.locale, 'Status'),
                'width': 130,
                render: (text, record) => {
                    const StagesOptions = (
                        <Menu>
                            {taskStages.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,option.id,"stage")}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );
                    return(!record.type ? <span>
                        <Dropdown overlay={StagesOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-flag-o"></i>
                            </a>
                        </Dropdown>
                        <span style={{whiteSpace: "nowrap"}} className={record.stageStatus} style={record.color ? {background: record.color, border: '1px solid #364a5a', color: "#000"} : {}}>{plainTranslate(this.props.settings.locale, text)}</span>
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex' : 'dueDate',
                'title' : plainTranslate(this.props.settings.locale, 'Due Date'),
                'width': 60,
            },
            {
                'dataIndex' : 'user',
                'title' : plainTranslate(this.props.settings.locale, 'User'),
                'width': 180,
                render: (text, record) => {

                    const loadOptions = (inputValue, callback) => {
                        client
                        .get('/' + this.props.match.params.db + '/api/find/users/search',{q: inputValue})
                        .then(response => response.data)
                        .then(response => {
                            let options = [];
                            if (response) {
                                options = response.map(option => {
                                    return {
                                        ...option,
                                        'value': option.id,
                                        'label': option.label
                                    };
                                });
                            }
                           
                            this.setState({userOptions: options});
                            return callback(options ? options : this.state.userOptions);
                        });
                    }
                    if(record.userId){
                        var exists = this.state.userOptions.find(o => o.id === record.userId);
                          if(!exists){
                            var userOptions = this.state.userOptions;
                            userOptions.push({id: record.userId, label: record.user})
                            this.setState({
                                userOptions: userOptions
                            })
                        }
                    }
                   let value = this.state.userValues[record.id] ? this.state.userValues[record.id] : null;                    
                    if(!value && record.userId){
                    value = record.userId;
                    }

                    if(value && value !== this.state.userValues[record.id]){
                        let userValues= this.state.userValues;
                        userValues[record.id] = value;
                        this.setState({
                            userValues: userValues
                        })
                    }
                    const customStyles = {
                        option: (base, state) => ({
                                ...base,
                                backgroundColor: state['data']['id'] === value ? "#CCCCCC" : (state.isFocused ? '#E7E0E0' : 'white'),
                                padding: 10,
                                color: '#444444'                    
                        })
                    };
                  return( !record.type ?  <AsyncSelect
                    loadOptions={loadOptions}
                    defaultOptions={users}
                    value={value && this.state.userOptions ? this.state.userOptions.find(o => o.id === value) : null}
                    styles={customStyles}   
                    classNamePrefix="mySelect"    
                    onChange={(id) => this.handleChangeField(null, record, id,'user')}
                  /> : <span></span>)
                },
            },
            {
                'dataIndex' : 'completion',
                'title' : plainTranslate(this.props.settings.locale, 'Completion'),
                'width': 100,
                render: (text, record) => {
                    const completionOptions = (
                        <Menu>
                            <Menu.Item key={1}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,10,'completion')}>
                                    10
                                </a>
                            </Menu.Item>
                             <Menu.Item key={2}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,20,'completion')}>
                                    20
                                </a>
                            </Menu.Item>
                            <Menu.Item key={3}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,30,'completion')}>
                                    30
                                </a>
                            </Menu.Item>
                            <Menu.Item key={4}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,40,'completion')}>
                                    40
                                </a>
                            </Menu.Item>
                            <Menu.Item key={5}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,50,'completion')}>
                                    50
                                </a>
                            </Menu.Item>
                            <Menu.Item key={6}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,60,'completion')}>
                                    60
                                </a>
                            </Menu.Item>
                            <Menu.Item key={7}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,70,'completion')}>
                                    70
                                </a>
                            </Menu.Item>
                            <Menu.Item key={8}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,80,'completion')}>
                                    80
                                </a>
                            </Menu.Item>
                            <Menu.Item key={9}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,90,'completion')}>
                                   90
                                </a>
                            </Menu.Item>
                            <Menu.Item key={10}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,100,'completion')}>
                                   100
                                </a>
                            </Menu.Item>
                        </Menu>
                    );
                    return(!record.type ? <span>
                        <Dropdown overlay={completionOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-line-chart"></i>
                            </a>
                        </Dropdown>
                        <Progress style={{width: "70%",marginLeft: "10px"}} percent={record.completion} />
                    </span> : <span></span>)
                }
            }
        ];

        let menuOptions = [];
        menuOptions = (
            <Menu>
                {taskForms && taskForms.length > 0 ? taskForms.map((option, i) => (
                    <Menu.Item key={i}>
                        <a className="dropdown-item" href="#" onClick={() => this.setState({ isOpenTask: true, taskForm: option.form, taskProject: null })}>
                            {plainTranslate(this.props.settings.locale, option.label)}
                        </a>
                    </Menu.Item>
                )) : 
                <Menu.Item key={1}>
                        <a className="dropdown-item" href="#" onClick={() => this.setState({ isOpenTask: true, taskForm: null, taskProject: null })}>
                            {plainTranslate(this.props.settings.locale, 'Task')}
                        </a>
                </Menu.Item>
                }
            </Menu>
        );

        let projectsAndTasksOptions = [];
        projectsAndTasksOptions = (
            <Menu>
                {projectForms && projectForms.length > 0 ? projectForms.map((option, i) => (
                    <Menu.Item key={i}>
                        <a className="dropdown-item" href="#" onClick={() => this.setState({ addNewProject: true, projectForm: option.form, taskProject: null })}>
                            {plainTranslate(this.props.settings.locale, "Project")}: {plainTranslate(this.props.settings.locale, option.label)}
                        </a>
                    </Menu.Item>
                    )) :
                    <Menu.Item key={1}>
                        <a className="dropdown-item" href="#" onClick={() => this.setState({ addNewProject: true, projectForm: null, taskProject: null })}>
                            {plainTranslate(this.props.settings.locale, "Project")}
                        </a>
                    </Menu.Item>
                }
                {taskForms && taskForms.length > 0 ? taskForms.map((option, i) => (
                    <Menu.Item key={i}>
                        <a className="dropdown-item" href="#" onClick={() => this.setState({ isOpenTask: true, taskForm: option.form, taskProject: null })}>
                            {plainTranslate(this.props.settings.locale, "Task")}:{plainTranslate(this.props.settings.locale, option.label)}
                        </a>
                    </Menu.Item>
                    )) :
                    <Menu.Item key={2}>
                        <a className="dropdown-item" href="#" onClick={() => this.setState({ isOpenTask: true, taskForm: null, taskProject: null })}>
                            {plainTranslate(this.props.settings.locale, "Task")}
                        </a>
                    </Menu.Item>
                }
            </Menu>
        );
      
       
        
    
        return(
            <div >
                 <span className="text-right">
                 <div className="btn-round btn-blue" style={{ top: '0' }}>
                        <Dropdown overlay={projectsAndTasksOptions} placement="bottomCenter" >
                        <PlusOutlined/>
                        </Dropdown>
                    </div>  
                 </span>
                <div className="text-center" style={{fontSize: 24, paddingLeft: 60, marginTop: 20, marginBottom: 20}}>
                <span>{plainTranslate(this.props.settings.locale, 'Projects')}</span>
                       
                </div>
                {projects && projects.map((project, key) => {
                    const projectTasksCols = [
                        {
                            key: 'actions',
                            dataIndex: 'actions',
                            title: plainTranslate(this.props.settings.locale, 'Actions'),
                            render: (text, record) => {
                                const FormOptions = (
                                    <Menu>
                                        {taskForms && taskForms.length > 0 ?  taskForms.map((option, i) => (
                                                <Menu.Item key={i}>
                                                    <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.createNewTask(e,option.form, project.id, record.id)}>
                                                        {plainTranslate(this.props.settings.locale, option.label)}
                                                    </a>
                                                </Menu.Item>
                                            )) :
                                            <Menu.Item key={1}>
                                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.createNewTask(e,null , project.id, record.id)}>
                                                    {plainTranslate(this.props.settings.locale, 'Task')}
                                                </a>
                                            </Menu.Item>
                                        }
                                    </Menu>
                                );
                                return (
                                    !record.type ? <span className="table-actions">
                                        <Link to={'/' + this.props.match.params.db + '/task/' + record.id + '/profile'} title={plainTranslate(this.props.settings.locale, 'Profile')}><i style={style} className="fa fa-eye"></i></Link>
                                        <i style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.setState({editTask: true, editTaskId: record.id})} className="fa fa-pencil-square-o"></i>
                                        <i style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.copyTask(record.id)} className="fa fa-clone"></i>
                                        <i style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.taskLogTime(record.id)} className="ion-android-time"></i>
                                        <Dropdown overlay={FormOptions}>
                                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                <i style={{margin: '0 5px',display: record.parent ? 'none' : 'inline-block'}} className="fa fa-plus-circle"></i>
                                            </a>
                                        </Dropdown>
                                
                                    </span> : <span></span>
                                )
                            },
                            width: 200,
                        },
                        {
                            'dataIndex' : 'name',
                            'title' : plainTranslate(this.props.settings.locale, 'Name'),
                            'width': 100,
                            'ellipsis': true,
                            render: (text, record) => {
                                return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showTaskHint: true, taskHint: record})}>{record.name}</span>)
                            }
                        },
                        {
                            'dataIndex' : 'status',
                            'title' : plainTranslate(this.props.settings.locale, 'Status'),
                            'width': 130,
                            render: (text, record) => {
                                const StagesOptions = (
                                    <Menu>
                                        {taskStages.map((option, i) => (
                                                <Menu.Item key={i}>
                                                    <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,option.id,"stage")}>
                                                        {plainTranslate(this.props.settings.locale, option.label)}
                                                    </a>
                                                </Menu.Item>
                                            ))
                                        }
                                    </Menu>
                                );
                                return(!record.type ? <span>
                                    <Dropdown overlay={StagesOptions}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            <i style={style} className="fa fa-flag-o"></i>
                                        </a>
                                    </Dropdown>
                                    <span style={{whiteSpace: "nowrap"}} className={record.stageStatus} style={record.color ? {background: record.color, border: '1px solid #364a5a', color: "#000"} : {}}>{plainTranslate(this.props.settings.locale, text)}</span>
                                </span> : <span></span>)
                            }
                        },
                        {
                            'dataIndex' : 'dueDate',
                            'title' : plainTranslate(this.props.settings.locale, 'Due Date'),
                            'width': 60,
                        },
                        {
                            'dataIndex' : 'user',
                            'title' : plainTranslate(this.props.settings.locale, 'Userss'),
                            'width': 180,
                            render: (text, record) => {

                                const loadOptions = (inputValue, callback) => {
                                    client
                                    .get('/' + this.props.match.params.db + '/api/find/users/search',{q: inputValue})
                                    .then(response => response.data)
                                    .then(response => {
                                        let options = [];
                                        if (response) {
                                            options = response.map(option => {
                                                return {
                                                    ...option,
                                                    'value': option.id,
                                                    'label': option.label
                                                };
                                            });
                                        }
                                       
                                        this.setState({userOptionsProject: options});
                                        return callback(options ? options : this.state.userOptionsProject);
                                    });
                                }
                               
                                if(record.userId){
                                    var exists = this.state.userOptionsProject.find(o => o.id === record.userId);
                                  if(!exists){
                                        var userOptionsProject = this.state.userOptionsProject;
                                        userOptionsProject.push({id: record.userId, label: record.user})
                                        this.setState({
                                            userOptionsProject: userOptionsProject
                                        })
                                    }
                                }
                                let value = this.state.userValuesProject[record.id] ? this.state.userValuesProject[record.id] : null;                    
                               
                                if(!value && record.userId){
                                   value = record.userId;
                                }
                             
                                if(value && value !== this.state.userValuesProject[record.id]){
                                    let userValuesProject= this.state.userValuesProject;
                                    userValuesProject[record.id] = value;
                                    this.setState({
                                        userValuesProject: userValuesProject
                                    })
                                }     
const customStyles = {
    option: (base, state) => ({
            ...base,
            backgroundColor: state['data']['id'] === value ? "#CCCCCC" : (state.isFocused ? '#E7E0E0' : 'white'),
            padding: 10,
            color: '#444444'                    
    })
};


                              return( !record.type ? <AsyncSelect                                
                                onBlur={event => event.preventDefault()}
                                loadOptions={loadOptions}
                                defaultOptions={users}
                                value={value && this.state.userOptionsProject ? this.state.userOptionsProject.find(o => o.id === value) : null}
                                classNamePrefix="mySelect"    
                                styles={customStyles}                 
                                onChange={(id) => this.handleChangeField(null, record, id,'userProject')}
                              /> : <span></span>)
                            },
                        },
                        {
                            'dataIndex' : 'completion',
                            'title' : plainTranslate(this.props.settings.locale, 'Completion'),
                            'width': 100,
                            render: (text, record) => {
                                const completionOptions = (
                                    <Menu>
                                        <Menu.Item key={1}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,10,'completion')}>
                                                10
                                            </a>
                                        </Menu.Item>
                                         <Menu.Item key={2}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,20,'completion')}>
                                                20
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={3}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,30,'completion')}>
                                                30
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={4}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,40,'completion')}>
                                                40
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={5}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,50,'completion')}>
                                                50
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={6}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,60,'completion')}>
                                                60
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={7}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,70,'completion')}>
                                                70
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={8}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,80,'completion')}>
                                                80
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={9}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,90,'completion')}>
                                               90
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={10}>
                                            <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,100,'completion')}>
                                               100
                                            </a>
                                        </Menu.Item>
                                    </Menu>
                                );
                                return(!record.type ? <span>
                                    <Dropdown overlay={completionOptions}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            <i style={style} className="fa fa-line-chart"></i>
                                        </a>
                                    </Dropdown>
                                    <Progress style={{width: "70%",marginLeft: "10px"}} percent={record.completion} />
                                </span> : <span></span>)
                            }
                        }
                    ];

                    let menuTasksOptions = [];
                    menuTasksOptions = (
                        <Menu>
                            {taskForms && taskForms.length > 0 ?taskForms.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a className="dropdown-item" href="#" onClick={() => this.setState({isOpenTask: true, taskForm: option.form, taskProject : project.id})}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                )) :
                                <Menu.Item key={1}>
                                    <a className="dropdown-item" href="#" onClick={() => this.setState({isOpenTask: true, taskForm: null, taskProject : project.id})}>
                                        {plainTranslate(this.props.settings.locale, 'Task')}
                                    </a>
                                </Menu.Item>
                            }
                        </Menu>
                    );
                    

                    return <div>
                        <div className="text-center" style={{fontSize: 24,position: "relative", marginTop: 20,marginBottom: 20}}>
                            <a className="project" href='#' onClick={() => this.setState({editProject: true, projectId: project.id, projectForm: project.form})}>{project.name}</a>
                                    <Dropdown overlay={menuTasksOptions} placement="bottomCenter" >
                                        <Button type="primary" className="newProjectsButton"><i className="ion-android-add "></i></Button>
                                    </Dropdown>
                             
                            <div className="project-hint">
                                <p>{plainTranslate(this.props.settings.locale, 'Status')} - {project.status}</p>
                                <p>{plainTranslate(this.props.settings.locale, 'Start Date')} - {project.dateStart ? moment((project.dateStart).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</p> 
                                <p>{plainTranslate(this.props.settings.locale, 'End Date')} - {project.dateEnd ? moment((project.dateEnd).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</p>
                            </div>
                        </div>
                        <Table 
                        className="projectsAndTasksTable" 
                        rowKey={record => record.id} 
                        columns={projectTasksCols} 
                        dataSource={project.tasks}
                        size="small"/>
                    </div>
                    })
                    
                }
                <div className="text-center" style={{fontSize: 24, paddingLeft: 60, marginTop: 20, marginBottom: 20}}>
                    <span>{plainTranslate(this.props.settings.locale, 'Tasks')}</span>
                        <div className="btn-round btn-blue" style={{float: 'right', position: 'relative'}}>
                            <Dropdown overlay={menuOptions} placement="bottomCenter" >
                            <PlusOutlined/>
                            </Dropdown>
                        </div>
                </div>
                {tasks && tasks.length < 1 &&
                    <ul>
                        <li>
                            <div className="conversation-text no-activities text-center">
                                <img src='/images/no-activities.svg' />
                                <h5>{plainTranslate(this.props.settings.locale, 'No tasks to show')}
                                </h5>
                            </div>
                        </li>
                    </ul>
                }
                {tasks && tasks.length > 0 &&
                    <Table 
                    className="projectsAndTasksTable" 
                    rowKey={record => record.id} 
                    columns={tasksCols} 
                    dataSource={tasks}
                    size="small"/>
                }
                {
                    this.state.isOpenTask &&
                    <SlideModal onClose={() => this.setState({isOpenTask: false, parentTask: null})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({isOpenTask: false, parentTask: null})}
                                        isNew={true}
                                        popup={true}
                                        parent={this.state.parentTask ? this.state.parentTask : null}
                                        account={this.props.accountId}
                                        type={this.state.taskForm}
                                        refs={this.state.taskProject ? this.state.taskProject : ' '}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.editProject &&
                    <SlideModal onClose={() => this.setState({editProject: false, projectId: false})} title={plainTranslate(this.props.settings.locale, 'Project')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/projects/'}
                                        saveRoute={this.props.match.params.db + '/api/projects/'}
                                        match={{params: {id: this.state.projectId}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({editProject: false})}
                                        isNew={true}
                                        popup={true}
                                        //account={this.props.accountId}
                                        //type={this.state.projectForm}
                                        //refs={this.state.taskProject ? this.state.taskProject : ' '}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.taskLogTime &&
                    <SlideModal onClose={() => this.setState({taskLogTime: false})} title={plainTranslate(this.props.settings.locale, 'Log time')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/log-time/'}
                                        saveRoute={this.props.match.params.db + '/api/log-time'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={this.closeLogTimePopup}
                                        isNew={true}
                                        popup={true}
                                        onCancel={() => this.setState({taskLogTime: false})}
                                        type={'task'}
                                        refs={this.state.taskId}
                                        //parent={this.props.match.params.id}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }

                {
                    this.state.openCopyTask &&
                    <SlideModal onClose={() => this.setState({openCopyTask: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({openCopyTask: false})}
                                        isNew={true}
                                        popup={true}
                                        copy={this.state.copyTaskId}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.editTask &&
                    <SlideModal onClose={() => this.setState({editTask: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks/'}
                                        match={{params: {id: this.state.editTaskId}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({editTask: false})}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.addNewProject &&
                    <SlideModal onClose={() => this.setState({addNewProject: false})} title={plainTranslate(this.props.settings.locale, 'Project')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/projects/'}
                                        saveRoute={this.props.match.params.db + '/api/projects'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({addNewProject: false})}
                                        isNew={true}
                                        popup={true}
                                        account={this.props.accountId}
                                        type={this.state.projectForm}
                                        //refs={this.state.taskProject ? this.state.taskProject : ' '}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {this.state.showTaskHint &&
                    <Popup locale={this.props.settings.locale}
                   title={this.state.taskHint.name}
                   onClose={() => this.setState({showTaskHint: false, taskHint: null})}
                   className="popupReports">
                        <div dangerouslySetInnerHTML={{ __html: this.state.taskHint.description }}> 
                        </div>
                    </Popup>
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAddNewProject: (data, projectId) => dispatch({type: ACCOUNT_ADD_NEW_PROJECT, payload: {project: data, projectId: projectId}}),
    onAddNewTask: (data, projectId) => dispatch({type: ACCOUNT_ADD_NEW_TASK, payload: {task: data, projectId: projectId}}),
    onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route,page, requiredTotal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Projects));

