export default function getTemplateRoutes(db) {
    return [
        {
            title: ['Sales', '›', 'Template'],
            path: '/:db/sales/:id/export.pdf',
        },
        {
            title: ['Sales', '›', 'Template'],
            path: '/:db/sales/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Invoice', '›', 'Template'],
            path: '/:db/invoicing/invoice/:id/export.pdf',
        },       
        {
            title: ['Invoice', '›', 'Template'],
            path: '/:db/invoicing/invoice/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Payments', '›', 'Template'],
            path: '/:db/payments/:id/export.pdf',
        },
        {
            title: ['Payments', '›', 'Template'],
            path: '/:db/payments/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Receipt', '›', 'Template'],
            path: '/:db/inventories/receipts/:id/export.pdf',
        },
        {
            title: ['Receipt', '›', 'Template'],
            path: '/:db/inventory/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Production', '›', 'Template'],
            path: '/:db/production/:id/export.pdf',
        },
        {
            title: ['Production', '›', 'Template'],
            path: '/:db/production/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Deal', '›', 'Template'],
            path: '/:db/export/deal/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Expense', '›', 'Template'],
            path: '/:db/expenses/:id/export.pdf',
        },
        {
            title: ['Expense', '›', 'Template'],
            path: '/:db/expenses/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Account', '›', 'Template'],
            path: '/:db/account/:id/template/:templateId/export.pdf',
        },
        {
            title: ['Invoice', '›', 'Template'],
            path: '/:db/public/invoice/:id/template/:templateId',
            additionalData: '/:db/web/additional-data/invoice/:id',
            public: true
        },
    ]
}